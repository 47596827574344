<template>
  <div class="item">
    <div class="item__title">{{ title }}</div>
    <div class="item-stats">
      <div class="item-stats-current">
        <div class="item__value">{{ valueOne | formatCount }}</div>
        <div :class="['item__diff', {_pos: diffPrc > 0, _neg: diffPrc < 0}]">
          <template v-if="diffPrc > 0">+</template>{{ diffPrc }}%
        </div>
      </div>
      <div class="item__prev">{{ valueTwo | formatCount }}</div>
    </div>
  </div>
</template>

<script>
import {roundNumber} from '@/function/roundNumber'

export default {
  name: 'statsItem',

  props: {
    title: {
      type: String,
      default: ''
    },

    valueOne: {
      type: Number,
      default: 0
    },

    valueTwo: {
      type: Number,
      default: 0
    },

    diff: {
      type: Number,
      default: 0
    }
  },

  computed: {
    diffPrc () {
      return this.diff === 0
          ? 0
          : this.diff === this.valueOne
              ? 100
              : roundNumber((this.diff * 100 / this.valueTwo), 1)
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  &__title {
    margin-bottom: 4px;
    color: #9d99aa;
    font-size: 16px;
    font-weight: 400;
  }

  &-stats {
    &-current {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
  }

  &__value {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #455165;
  }

  &__prev {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #bdbdbd
  }

  &__diff {
    height: 24px;
    padding: 0 6px;
    border-radius: 6px;
    line-height: 24px;
    background: #bdbdbd;
    color: #fff;
    font-size: 16px;
    font-weight: 500;

    &._pos {
      background: #00C853;
    }

    &._neg {
      background: #F44336;
    }
  }
}
</style>
