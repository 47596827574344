<template>
  <v-chart
    ref="chart"
    v-model="chartData"
    lib="highcharts"
    dataforce
  />
</template>

<script>
export default {
  name: 'categoryChartColumn',

  props: {
    items: {
      type: Array,
      default: []
    },

    metric: {
      type: String,
      default: ''
    },

    categoryColors: {
      type: Object,
      default: () => ({})
    },

    date: {
      type: Object,
      default: () => ({})
    },

    datePrev: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      itemColors: {}
    }
  },

  computed: {
    chartData() {
      let series = []

      try {
        series.push({
          name: `${this.datePrev.from} - ${this.datePrev.to}`,
          color: '#BDBDBD',
          pointPlacement: 0.2,
          linkedTo: 'current',
          data: [...this.items].map(item => {
            try {
              return {
                name: item.name,
                y: item[`${this.metric}_two`]
              }
            } catch (_) {
              return undefined
            }
          })
        })
      } catch (_) {
      }

      try {
        series.push({
          id: 'current',
          name: `${this.date.from} - ${this.date.to}`,
          dataLabels: [{
            enabled: true,
            inside: true,
            style: {
              fontSize: '14px',
              textOutline: 'none'
            },
            format: '{point.y:,.2f}'
          }],
          data: [...this.items].map(item => {
            try {
              return {
                name: item.name,
                y: item[`${this.metric}_one`],
                color: this.itemColors[item.id] || '#2D3646'
              }
            } catch (_) {
              return undefined
            }
          })
        })
      } catch (_) {
      }

      return {
        chart: {
          type: 'column',
          height: '264px'
        },

        legend: {
          enabled: false
        },

        xAxis: {
          type: 'category',
          categories: [...this.items.map(item => item.name)],
          labels: {
            align: 'center',
            autoRotation: undefined,
            style: {
              color: '#2D3646'
            }
          }
        },

        yAxis: {
          gridLineDashStyle: 'Dash',
          labels: {
            format: '{value}%'
          }
        },

        plotOptions: {
          column: {
            borderWidth: 0,
            borderRadius: 3,
            pointWidth: 47
          }
        },

        tooltip: {
          pointFormat: `
            <div style="line-height: 18px;">
                <span style="font-size: 16px; color:{point.color};">\u25CF</span> {series.name}: <b>{point.y:,.2f}%</b>
            </div>
          `
        },

        series,

        exporting: {
          enabled: false
        }
      }
    }
  },

  created() {
    let colors = {}
    this.items.forEach(item => {
      colors[item.id] = this.categoryColors[item.id]
    })
    this.itemColors = colors
  },

  mounted() {
    if (this.$refs.chart) {
      this.$refs.chart.$refs.highcharts.chart.reflow()
    }
  }
}
</script>
