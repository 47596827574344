<template>
  <div :class="['nodata', `_${noDataType}`]">
    <icon v-if="!hideIcon" name="empty-state" class="nodata__icon"/>

    <div class="nodata__content">
      <slot name="nodata">
        <div class="nodata__title">{{ noData.title }}</div>
        <div class="nodata__note" v-if="noData.note">{{ noData.note }}</div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /* Данные для отображения заглушки */
    noData: {
      type: Object,
      default: () => {
        return {
          title: 'Данные отсутствуют'
        }
      }
    },

    noDataType: {
      type: String,
      default: 'horizontal',
      validator: function (value) {
        return ['vertical', 'horizontal'].indexOf(value) !== -1
      }
    },

    hideIcon: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped src="./errors.scss"></style>
