<template>
  <vue-simple-table
    class="table"
    small-padding
    head-background
    bordered
    :hoverable-rows="false"
  >
    <template #colgroup>
      <col :width="`${100 - metricColWidth * (columns.length + 1)}%`">
      <col
        v-for="_ of columns"
        :width="`${metricColWidth}%`"
      >
    </template>
    <template #head>
      <vue-line type="head">
        <vue-item>Категории</vue-item>
        <vue-item
          v-for="(column, i) of columns"
          :key="i"
        >
          {{ column.name }}
        </vue-item>
        <vue-item>Всего</vue-item>
      </vue-line>
    </template>
    <template #body>
      <vue-line
        v-for="(item, i) of items"
        :key="i"
      >
        <vue-item>{{ item.name }}</vue-item>
        <vue-item
          v-for="(column, i) of columns"
          :key="i + column.id"
          align="right"
        >
          <div class="metric">
            <div class="metric__value">
              {{ getValue(item.devices, column.id) | formatCount }}
              <template v-if="metric.percent">%</template>
            </div>

            <vue-element
              v-if="item.devices[column.id][`${metric.id}_diff`]"
              class="metric__diff"
              type="label"
              :data="getDiff(item.devices[column.id], column.id)"
              :locale-options="{maximumFractionDigits: 0}"
              temperature
              label-note="%"
            />
          </div>
        </vue-item>
        <vue-item align="right">
          <div class="metric">
            <div class="metric__value">
              {{
                (item[`${metric.id}_one`] || item[`${metric.id}_one`] === 0 ? item[`${metric.id}_one`] : '-') | formatCount
              }}
              <template v-if="metric.percent">%</template>
            </div>

            <vue-element
              v-if="item[`${metric.id}_diff`]"
              class="metric__diff"
              type="label"
              :data="getDiff(item)"
              :locale-options="{maximumFractionDigits: 0}"
              temperature
              label-note="%"
            />
          </div>
        </vue-item>
      </vue-line>
    </template>
  </vue-simple-table>
</template>

<script>
import {roundNumber} from '@/function/roundNumber'

export default {
  name: 'categoryTableCategory',

  props: {
    items: {
      type: Array,
      default: () => ([])
    },

    columns: {
      type: Array,
      default: () => ([])
    },

    metric: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      metricColWidth: 20
    }
  },

  methods: {
    roundNumber,

    getValue(data, deviceId) {
      return data && (data[deviceId][`${this.metric.id}_one`] || data[deviceId][`${this.metric.id}_one`] === 0)
        ? data[deviceId][`${this.metric.id}_one`]
        : '-'
    },

    getDiff(data) {
      if (data) {
        const one = data[`${this.metric.id}_one`]
        const two = data[`${this.metric.id}_two`]
        const diff = data[`${this.metric.id}_diff`]

        return diff === undefined
          ? undefined
          : diff === 0
            ? 0
            : diff === one
              ? 100
              : roundNumber((diff * 100 / two), 0)
      } else {
        return undefined
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  & /deep/ thead .table-item {
    background: linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), #C2C9D1 !important;
    color: #455165;
  }
}

.metric {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 4px;

  &__diff._bg {
    height: 16px;
    padding: 0 4px;
    line-height: 16px;
    font-size: 12px;
  }
}
</style>
