var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['card', {_hovered: _vm.hovered && _vm.draggingActive}],style:(_vm.styles)},[_c('div',{staticClass:"card-controls"},[_c('div',{class:['card__visibility', {_disabled: _vm.disableVisibilityToggle}],on:{"click":_vm.hideCard}},[_c('icon',{attrs:{"name":"visibility_off"}})],1),_c('icon',{class:[
          'card__drag-handler',
          {
            _hovered: _vm.hovered && _vm.draggingActive,
             _grabbed: _vm.draggingActive
          }
      ],attrs:{"name":"drag_indicator"},nativeOn:{"mousedown":function($event){_vm.hovered = true},"mouseup":function($event){_vm.hovered = false}}})],1),(_vm.imageName)?_c('div',{staticClass:"card-image"},[_c('img',{attrs:{"src":`${_vm.getImgUrl()}`,"alt":_vm.title}})]):_vm._e(),_c('div',{staticClass:"card-head"},[(_vm.title)?_c('span',{staticClass:"card__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.description)?_c('div',{staticClass:"card__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),(_vm.showTags && _vm.title)?_c('v-page-tags',{staticClass:"_p-0 card__tags",attrs:{"tags":_vm.tagList,"clearable":false}}):_vm._e()],1),(!_vm.hasData && !_vm.load)?_c('v-no-data'):[_c('div',{class:['card-content', {'_load': _vm.load}]},[(_vm.load)?_c('v-preloader',{staticClass:"_relative"}):_vm._t("default")],2),_c('div',{staticClass:"card-buttons"},[_vm._t("buttons",function(){return [(_vm.link.to)?_c('v-button',{attrs:{"type":"base","name":"Подробнее","to":_vm.link.to}}):_vm._e()]})],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }