var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._b({on:{"select-period":function($event){return _vm.$emit('select-period', $event)},"hide-card":function($event){return _vm.$emit('hide-card', $event)}}},'v-card',{
      class: '_w-100',
      title: _vm.title,
      description: 'Данные ' + _vm.label,
      hasData: !!_vm.items.length,
      showTags: true,
      load: _vm.load,
      link: _vm.link,
      tags: _vm.tags,
      periodSelected: _vm.periodSelected,
      id: _vm.id,
      minHeight: '437px'
    },false),[_c('v-table-entity-diff',_vm._b({},'v-table-entity-diff',{
        items: _vm.items,
        period: _vm.periodSelected,
        columns: _vm.columns
      },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }