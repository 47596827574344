<template>
  <v-card
    v-bind="{
        class: '_w-100',
        title,
        description: 'Данные ' + label,
        hasData: !!items.length,
        showTags: true,
        load,
        link,
        tags,
        periodSelected,
        id,
        minHeight: '437px'
      }"
    @select-period="$emit('select-period', $event)"
    @hide-card="$emit('hide-card', $event)"
  >
    <v-table-entity-diff
      v-bind="{
          items,
          period: periodSelected,
          columns
        }"
    />
  </v-card>
</template>

<script>
import card from '../card'
import tableEntityDiff from '../tableEntityDiff'

export default {
  name: 'cardEntityDiff',

  components: {
    'v-card': card,
    'v-table-entity-diff': tableEntityDiff
  },

  props: {
    id: {
      type: String,
      default: ''
    },

    load: {
      type: Boolean,
      default: false
    },

    link: {
      type: Object,
      default: () => ({})
    },

    tags: {
      type: Array,
      default: () => ([])
    },

    periodSelected: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    items: {
      type: Array,
      default: () => []
    },

    columns: {
      type: Array,
      default: () => []
    }
  }
}
</script>
