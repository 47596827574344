<template>
  <v-chart
    ref="chart"
    v-model="chartData"
    lib="highcharts"
    dataforce
  />
</template>

<script>
export default {
  name: 'categoryChartPie',

  props: {
    items: {
      type: Array,
      default: []
    },

    metric: {
      type: String,
      default: ''
    },

    categoryColors: {
      type: Object,
      default: () => ({})
    },

    date: {
      type: Object,
      default: () => ({})
    },

    datePrev: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      itemColors: {}
    }
  },

  computed: {
    chartData() {
      const series = [
        {
          data: [],
          innerSize: '65%'
        }
      ]
      let sum
      if (this.items.length) {
        sum = this.items.reduce((acc, el) => {
          acc += el[`${this.metric}_one`]
          return acc
        }, 0)
        this.items.forEach(item => {
          series[0].data.push({
            id: item.id,
            name: item.name,
            y: Math.round((item[`${this.metric}_one`] / sum) * 100),
            color: this.itemColors[item.id],
            absolute: item[`${this.metric}_one`]
          })
        })
      }
      return {
        chart: {
          type: 'pie',
          height: '315px',
          style: {
            margin: '0 auto'
          }
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<span style="font-weight: 400">{point.name}</span><br><b>{point.absolute:,.0f}</b>',
              connectorWidth: 1,
              styles: {
                color: '#2D3646',
                fontSize: '12px',
                textOutline: 'none',
                textOverflow: 'wrap'
              }
            },
            size: '80%',
            center: ['50%', '50%']
          }
        },
        title: {
          align: 'center',
          text: `
                <span style="color: #455165; font-size: 24px">${sum.toLocaleString('ru-RU')}</span><br>
                <span style="color: #9D99AA; font-size: 14px">Всего</span>
          `,
          verticalAlign: 'middle',
          y: 5
        },
        tooltip: {
          pointFormat: '<b>{point.absolute:,.0f}</b>'
        },
        series,
        exporting: {
          enabled: false
        }
      }
    }
  },

  created() {
    let colors = {}
    this.items.forEach(item => {
      colors[item.id] = this.categoryColors[item.id]
    })
    this.itemColors = colors
  },

  mounted() {
    if (this.$refs.chart) {
      this.$refs.chart.$refs.highcharts.chart.reflow()
    }
  }
}
</script>
