import CARD_NAME from "@/services/dashboard/pages/quickStart/cardName";

const requestDataPeriodStaticItems = {
  requestName: 'requestDataPeriodStatic',
  requestConfig: {name: 'itemsDefault'}
}

const requestDataPeriodStaticSummary = {
  requestName: 'requestDataPeriodStatic',
  requestConfig: {
    name: 'summaryDefault',
    summary: true
  }
}

const requestDataGeoSummary = {
  requestName: 'requestDataGeo',
  requestConfig: {
    name: 'summaryGeo',
    summary: 1
  }
}

const requestDataTopGeoSummary = {
  sessions: {
    requestName: 'requestDataTopGeo',
    requestConfig: {
      name: {
        metric: 'sessions',
        cacheId: 'summaryTopGeo'
      },
      summary: 1
    }
  },
  transaction_revenue: {
    requestName: 'requestDataTopGeo',
    requestConfig: {
      name: {
        metric: 'transaction_revenue',
        cacheId: 'summaryTopGeo'
      },
      summary: 1
    }
  },
  transactions: {
    requestName: 'requestDataTopGeo',
    requestConfig: {
      name: {
        metric: 'transactions',
        cacheId: 'summaryTopGeo'
      },
      summary: 1
    }
  },
  conversions: {
    requestName: 'requestDataTopGeo',
    requestConfig: {
      name: {
        metric: 'conversions',
        cacheId: 'summaryTopGeo'
      },
      summary: 1
    }
  },
}

const requestDataGeoItems = {
  requestName: 'requestDataGeo',
  requestConfig: {name: 'itemsGeo'}
}

const requestDataTopGeoItems = {
  sessions: {
    requestName: 'requestDataTopGeo',
    requestConfig: {
      name: {
        metric: 'sessions',
        cacheId: 'itemsTopGeo'
      }
    }
  },
  transaction_revenue: {
    requestName: 'requestDataTopGeo',
    requestConfig: {
      name: {
        metric: 'transaction_revenue',
        cacheId: 'itemsTopGeo'
      }
    }
  },
  transactions: {
    requestName: 'requestDataTopGeo',
    requestConfig: {
      name: {
        metric: 'transactions',
        cacheId: 'itemsTopGeo'
      }
    }
  },
  conversions: {
    requestName: 'requestDataTopGeo',
    requestConfig: {
      name: {
        metric: 'conversions',
        cacheId: 'itemsTopGeo'
      }
    }
  },
}

function getRequestDataByMetric (metric) {
  return [
    requestDataPeriodStaticItems,
    requestDataPeriodStaticSummary,
    requestDataTopGeoSummary[metric],
    requestDataTopGeoItems[metric]
  ]
}

function getRequestItemsDataByMetric (metric) {
  return [
    requestDataPeriodStaticItems,
    requestDataTopGeoItems[metric]
  ]
}

export default {
  requestDataPeriodStaticItems: [requestDataPeriodStaticItems],
  periodStaticFull: [
    requestDataPeriodStaticItems,
    requestDataPeriodStaticSummary
  ],
  requestDataGeoSummary: [requestDataGeoSummary],
  requestDataGeoSummaryFull: [
    requestDataGeoSummary,
    requestDataPeriodStaticSummary
  ],
  requestDataGeoFull: [
    requestDataPeriodStaticItems,
    requestDataPeriodStaticSummary,
    requestDataGeoSummary,
    requestDataGeoItems
  ],
  requestDataGeoItemsFull: [
    requestDataPeriodStaticItems,
    requestDataGeoItems
  ],
  requestDataTopGeoFull: getRequestDataByMetric,
  requestDataTopGeoItemsFull: getRequestItemsDataByMetric,
  requestDataPeriodStaticChannels: [
    requestDataPeriodStaticItems,
    requestDataPeriodStaticSummary,
    {
      requestName: 'requestDataChannels',
      requestConfig: {
        name: 'summaryChannels',
        summary: 1
      }
    },
    {
      requestName: 'requestDataChannels',
      requestConfig: {name: 'itemsChannels'}
    }
  ],
  requestDataPeriodDynamicChannels: period => [
    {
      requestName: 'requestDataPeriodDynamic',
      requestConfig: {
        name: 'itemsPeriod',
        periodSelected: period
      }
    },
    {
      requestName: 'requestDataChannelsPeriodDynamic',
      requestConfig: {
        name: 'itemsChannelsPeriod',
        periodSelected: period
      }
    },
    {
      requestName: 'requestDataPeriodDynamic',
      requestConfig: {
        name: 'summaryPeriod',
        summary: true,
        periodSelected: period,
        type: 'df'
      }
    },
    {
      requestName: 'requestDataPeriodDynamic',
      requestConfig: {
        name: 'summaryPeriod',
        summary: true,
        periodSelected: period,
        type: 'ga'
      }
    },
    {
      requestName: 'requestDataChannelsPeriodDynamic',
      requestConfig: {
        name: 'summaryChannelsPeriod',
        periodSelected: period,
        summary: 1,
        type: 'df'
      }
    },
    {
      requestName: 'requestDataChannelsPeriodDynamic',
      requestConfig: {
        name: 'summaryChannelsPeriod',
        periodSelected: period,
        summary: 1,
        type: 'ga'
      }
    },
  ],
  requestDataPeriodDynamicEntitiesChannels: period => [
    {
      requestName: 'requestDataPeriodDynamic',
      requestConfig: {
        name: 'summaryPeriod',
        summary: true,
        periodSelected: period,
        type: 'df'
      }
    },
    {
      requestName: 'requestDataPeriodDynamic',
      requestConfig: {
        name: 'summaryPeriod',
        summary: true,
        periodSelected: period,
        type: 'ga'
      }
    },
    {
      requestName: 'requestDataEntity',
      requestConfig: {
        name: 'entitiesSummaryPeriod',
        periodSelected: period
      }
    },
    {
      requestName: 'requestDataEntityChannelsPeriodDynamic',
      requestConfig: {
        name: 'entitiesChannelsPeriod',
        periodSelected: period
      }
    },
    {
      requestName: 'requestDataChannelsPeriodDynamic',
      requestConfig: {
        name: 'summaryChannelsPeriod',
        periodSelected: period,
        summary: 1,
        type: 'df'
      }
    },
    {
      requestName: 'requestDataChannelsPeriodDynamic',
      requestConfig: {
        name: 'summaryChannelsPeriod',
        periodSelected: period,
        summary: 1,
        type: 'ga'
      }
    }
  ],
  requestDataPeriodDynamicEcom: period => [
    {
      requestName: 'requestDataPeriodDynamic',
      requestConfig: {
        name: 'itemsEcomPeriod',
        periodSelected: period
      }
    },
    {
      requestName: 'requestDataChannelsPeriodDynamic',
      requestConfig: {
        name: 'itemsEcomChannelsPeriod',
        periodSelected: period
      }
    },
    {
      requestName: 'requestDataPeriodDynamic',
      requestConfig: {
        name: 'summaryEcomPeriod',
        summary: true,
        periodSelected: period,
        type: 'df'
      }
    },
    {
      requestName: 'requestDataChannelsPeriodDynamic',
      requestConfig: {
        name: 'summaryEcomChannelsPeriod',
        periodSelected: period,
        summary: 1,
        type: 'df'
      }
    }
  ]
}
