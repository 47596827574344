import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import {parallelLimit} from 'async-es'
import {findOptions, findKey} from '@/function'
import {DATE_FORMAT, NOTIFY_STATUS, PERIOD_OPTIONS} from '@/const'
import ROUTE_NAME from '@/router/routeName'
import CARD_NAME from './cardName'
import TAG_LABELS from './tagLabels'
import MODAL_TITLE from '@/modals/modalTitle'
import CARD_GROUP_NAME from './cardGroupName'
import requestFuncConfig from './requestFuncConfig'

import card from './components/card'
import categoryTable from './components/categoryTable'
import categoryChartColumn from './components/categoryChartColumn'
import categoryChartPie from './components/categoryChartPie'
import statsItem from './components/statsItem'
import draggable from 'vuedraggable'
import categoryTableGeo from './components/categoryTableGeo'
import cardDynamics from './components/cards/cardDynamics'
import categoryTableChannels from './components/categoryTableChannels'
import cardChannelsDiff from './components/cards/cardChannelsDiff'
import cardEntityDiff from './components/cards/cardEntityDiff'
import cardEntityChannelsDiff from './components/cards/cardEntityChannelsDiff'
import categoryTableDevice from './components/categoryTableDevice'

const CARD_CONFIG_NAME = 'quickStartCards'

const DEFAULT_PERIOD = 'month_to_month'

export default {
  name: 'quickStart',

  metaInfo() {
    return {
      title: `${this.title} / ${this.activeProjectData.label} (${this.activeProjectData.id})`
    }
  },

  components: {
    'v-card': card,
    'v-category-table': categoryTable,
    'v-category-chart-column': categoryChartColumn,
    'v-category-chart-pie': categoryChartPie,
    'v-stats-item': statsItem,
    'v-draggable': draggable,
    'v-category-table-geo': categoryTableGeo,
    'v-card-dynamics': cardDynamics,
    'v-category-table-channels': categoryTableChannels,
    'v-card-channels-diff': cardChannelsDiff,
    'v-card-entity-diff': cardEntityDiff,
    'v-card-entity-channels-diff': cardEntityChannelsDiff,
    'v-category-table-device': categoryTableDevice,
  },

  data() {
    return {
      title: 'Quick Start',

      preloader: {
        page: true,
        itemsDefault: true,
        summaryDefault: true,
        summaryPeriod: {
          df: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          },
          ga: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          }
        },
        summaryGeo: true,
        summaryTopGeo: {
          sessions: true,
          transaction_revenue: true,
          transactions: true,
          conversions: true
        },
        itemsGeo: true,
        itemsTopGeo: {
          sessions: true,
          transaction_revenue: true,
          transactions: true,
          conversions: true
        },
        summaryChannels: true,
        itemsChannels: true,
        summaryChannelsPeriod: {
          df: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          },
          ga: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          }
        },
        itemsChannelsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        itemsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        entitiesSummaryPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        entitiesChannelsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        itemsEcomDefault: true,
        summaryEcomDefault: true,
        itemsEcomPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        itemsEcomChannelsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        summaryEcomPeriod: {
          df: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          },
          ga: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          }
        },
        summaryEcomChannelsPeriod: {
          df: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          },
          ga: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          }
        },
        devicesSummaryPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        devicesChannelsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        summaryDevices: true,
        itemsDevices: true
      },

      needsData: {
        itemsDefault: true,
        summaryDefault: true,
        summaryPeriod: {
          df: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          },
          ga: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          }
        },
        summaryGeo: true,
        summaryTopGeo: {
          sessions: true,
          transaction_revenue: true,
          transactions: true,
          conversions: true
        },
        itemsGeo: true,
        itemsTopGeo: {
          sessions: true,
          transaction_revenue: true,
          transactions: true,
          conversions: true
        },
        summaryChannels: true,
        itemsChannels: true,
        summaryChannelsPeriod: {
          df: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          },
          ga: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          }
        },
        itemsChannelsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        itemsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        entitiesSummaryPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        entitiesChannelsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        itemsEcomDefault: true,
        summaryEcomDefault: true,
        itemsEcomPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        itemsEcomChannelsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        summaryEcomPeriod: {
          df: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          },
          ga: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          }
        },
        summaryEcomChannelsPeriod: {
          df: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          },
          ga: {
            [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
            [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
            [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY]: true,
            [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
          }
        },
        devicesSummaryPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        devicesChannelsPeriod: {
          [PERIOD_OPTIONS.WEEK_TO_WEEK]: true,
          [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH]: true,
          [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY]: true,
          [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: true
        },
        summaryDevices: true,
        itemsDevices: true
      },

      CARD_NAME,
      ROUTE_NAME,

      entityColors: {},
      availableColors: [],

      draggingActive: false,

      cardList: [],

      requestQueue: [],

      dragOptions: {
        animation: 0,
        scrollSensitivity: 150,
        forceFallback: true,
        scrollSpeed: 15,
        fallbackOnBody: true
      },

      periodSelected: {
        [CARD_NAME.DYNAMICS]: DEFAULT_PERIOD,
        [CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]: DEFAULT_PERIOD,
        [CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]: DEFAULT_PERIOD,
        [CARD_NAME.ENTITY_METRIC_SUMMARY]: DEFAULT_PERIOD,
        [CARD_NAME.ENTITY_CHANNELS_TRAFFIC]: DEFAULT_PERIOD,
        [CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]: DEFAULT_PERIOD,
        [CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS]: DEFAULT_PERIOD,
        [CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS]: DEFAULT_PERIOD,
        [CARD_NAME.DEVICE_METRIC_SUMMARY]: DEFAULT_PERIOD,
        [CARD_NAME.DEVICE_CHANNELS_TRAFFIC]: DEFAULT_PERIOD,
        [CARD_NAME.DEVICE_CHANNELS_REVENUE]: DEFAULT_PERIOD,
      },

      headerHeight: 0
    }
  },

  computed: {
    ...mapState({
      activeProjectData: state => state.active_project_data,
      chartColors: state => state.chartColors,
      categories: state => state.dashboard.quickStart.categories,
      summaryGeoMetrics: state => state.dashboard.quickStart.summaryGeoMetrics,
      filtersOptions: state => state.filtersOptions,
      filtersTrafficMedium: state => state.filtersTraffic.medium,
      periodOptions: state => state.dashboard.quickStart.periodOptions,
      catalogEntities: state => state.dashboard.quickStart.catalogEntities,
      summaryEntityMetrics: state => state.dashboard.quickStart.summaryEntityMetrics,
      topRegions: state => state.dashboard.quickStart.topRegions,
      topRegionsMetrics: state => state.dashboard.quickStart.summaryTopGeoMetrics,
    }),

    ...mapGetters({
      filtersRegions: 'filtersRegions',
      metricData: 'dashboard/quickStart/metricData',
      summaryGeo: 'dashboard/quickStart/summaryGeo',
      metricDataGeo: 'dashboard/quickStart/metricDataGeo',
      metricDataChannels: 'dashboard/quickStart/metricDataChannels',
      metricDataChannelsPeriod: 'dashboard/quickStart/metricDataChannelsPeriod',
      summaryEntitiesPeriod: 'dashboard/quickStart/summaryEntitiesPeriod',
      entitiesChannelsPeriod: 'dashboard/quickStart/entitiesChannelsPeriod',
      metricsData: 'dashboard/quickStart/metricsData',
      filtersDevices: 'filtersDevices',
      metricDataDevices: 'dashboard/quickStart/metricDataDevices',
      top5RegionsByMetric: 'dashboard/quickStart/top5RegionsByMetric',
      widgetConfig: 'dashboard/quickStart/widgetConfig',
    }),

    /* ID активного проекта в URL */
    activeProjectId() {
      let id = this.$route.params.activeProject
      return id ? +id : undefined
    },

    weekCurrent() {
      return {
        from: this.$moment.utc().startOf('day').subtract(8, 'days').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc().startOf('day').subtract(1, 'days').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    weekPrev() {
      return {
        from: this.$moment.utc(this.weekCurrent.from, DATE_FORMAT.BASE_FORMAT).startOf('day').subtract(8, 'days').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc(this.weekCurrent.from, DATE_FORMAT.BASE_FORMAT).startOf('day').subtract(1, 'days').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    weekPrevYear() {
      return {
        from: this.$moment.utc(this.weekCurrent.from, DATE_FORMAT.BASE_FORMAT).startOf('day').subtract(1, 'year').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc(this.weekCurrent.to, DATE_FORMAT.BASE_FORMAT).startOf('day').subtract(1, 'year').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    monthCurrent() {
      return {
        from: this.$moment.utc().startOf('day').subtract(31, 'days').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc().startOf('day').subtract(1, 'days').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    monthPrev() {
      return {
        from: this.$moment.utc(this.monthCurrent.from, DATE_FORMAT.BASE_FORMAT).startOf('day').subtract(30, 'days').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc(this.monthCurrent.from, DATE_FORMAT.BASE_FORMAT).startOf('day').subtract(1, 'days').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    monthPrevYear() {
      return {
        from: this.$moment.utc(this.monthCurrent.from, DATE_FORMAT.BASE_FORMAT).startOf('day').subtract(1, 'year').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc(this.monthCurrent.to, DATE_FORMAT.BASE_FORMAT).startOf('day').subtract(1, 'year').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    dayCurrent() {
      const yesterday = this.$moment.utc().startOf('day').subtract(1, 'days').format(DATE_FORMAT.BASE_FORMAT)
      return {
        from: yesterday,
        to: yesterday
      }
    },

    dayPrev() {
      const dayPrev = this.$moment.utc().startOf('day').subtract(2, 'days').format(DATE_FORMAT.BASE_FORMAT)
      return {
        from: dayPrev,
        to: dayPrev
      }
    },

    dayPrevWeek() {
      const dayPrevWeek = this.$moment.utc().startOf('day').subtract(1, 'days').subtract(1, 'week').format(DATE_FORMAT.BASE_FORMAT)
      return {
        from: dayPrevWeek,
        to: dayPrevWeek
      }
    },

    calendarWeekCurrent() {
      return {
        from: this.$moment.utc().startOf('week').subtract(1, 'weeks').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc().endOf('week').subtract(1, 'weeks').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    calendarWeekPrev() {
      return {
        from: this.$moment.utc(this.calendarWeekCurrent.from, DATE_FORMAT.BASE_FORMAT).subtract(1, 'weeks').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc(this.calendarWeekCurrent.to, DATE_FORMAT.BASE_FORMAT).subtract(1, 'weeks').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    calendarWeekPrevYear() {
      return {
        from: this.$moment.utc(this.calendarWeekCurrent.from, DATE_FORMAT.BASE_FORMAT).subtract(1, 'years').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc(this.calendarWeekCurrent.to, DATE_FORMAT.BASE_FORMAT).subtract(1, 'years').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    calendarMonthCurrent() {
      return {
        from: this.$moment.utc().startOf('month').subtract(1, 'months').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc().endOf('month').subtract(1, 'months').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    calendarMonthPrev() {
      return {
        from: this.$moment.utc(this.calendarMonthCurrent.from, DATE_FORMAT.BASE_FORMAT).subtract(1, 'months').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc(this.calendarMonthCurrent.to, DATE_FORMAT.BASE_FORMAT).subtract(1, 'months').endOf('month').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    calendarMonthPrevYear() {
      return {
        from: this.$moment.utc(this.calendarMonthCurrent.from, DATE_FORMAT.BASE_FORMAT).subtract(1, 'years').format(DATE_FORMAT.BASE_FORMAT),
        to: this.$moment.utc(this.calendarMonthCurrent.to, DATE_FORMAT.BASE_FORMAT).subtract(1, 'years').endOf('month').format(DATE_FORMAT.BASE_FORMAT)
      }
    },

    dateFormatted() {
      return {
        from: this.$moment.utc(this.monthCurrent.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE_DOT),
        to: this.$moment.utc(this.monthCurrent.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE_DOT)
      }
    },

    datePrevFormatted() {
      return {
        from: this.$moment.utc(this.monthPrev.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE_DOT),
        to: this.$moment.utc(this.monthPrev.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE_DOT)
      }
    },

    cardsConfig() {
      return {
        [CARD_NAME.LEADERS_SALES_TOP5]: {
          id: CARD_NAME.LEADERS_SALES_TOP5,
          title: 'Лидеры продаж (Транзакции)',
          description: 'Продажи за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault,
          link: this.getCatalogLink('transactions', 'DESC'),
          type: 'table',
          items: this.leadersSalesTop5,
          columns: [
            {id: 'transactions', name: 'GA'},
            {id: 'transactions_crm', name: 'CRM'}
          ],
          hasData: !!this.leadersSalesTop5.length,
          requestFunc: requestFuncConfig.periodStaticFull,
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.LEADERS_CR_TOP5]: {
          id: CARD_NAME.LEADERS_CR_TOP5,
          title: 'Конверсия по категориям',
          description: 'Конверсия за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault,
          link: this.getCatalogLink('conversions_one', 'DESC', true),
          type: 'chart-column',
          items: this.leadersCRTop5,
          metric: 'conversions',
          hasData: !!this.leadersCRTop5.length,
          requestFunc: requestFuncConfig.periodStaticFull,
          parent: [CARD_GROUP_NAME.CONVERSION, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.LEADERS_TRAFFIC_TOP5]: {
          id: CARD_NAME.LEADERS_TRAFFIC_TOP5,
          title: 'Лидеры трафика',
          description: 'Трафик за последние 30 дней по ТОП5 категорий',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault,
          link: this.getCatalogLink('sessions', 'DESC'),
          type: 'table',
          items: this.leadersTrafficTop5,
          columns: [{id: 'count_all', name: 'Трафик'}],
          hasData: !!this.leadersTrafficTop5.length,
          requestFunc: requestFuncConfig.periodStaticFull,
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.DEMAND]: {
          id: CARD_NAME.DEMAND,
          title: 'Спрос по категориям',
          description: 'Поисковый спрос в Яндекс за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault,
          link: this.getCatalogLink('ws2', 'DESC'),
          type: 'chart-pie',
          items: this.demand,
          metric: 'ws2',
          class: '_h-505',
          hasData: !!this.demand.length,
          requestFunc: requestFuncConfig.periodStaticFull,
          parent: [CARD_GROUP_NAME.DEMAND_VISIBILITY, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.DYNAMICS]: {
          id: CARD_NAME.DYNAMICS,
          title: 'Динамика ключевых показателей проекта',
          component: 'card-dynamics',
          load: this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.DYNAMICS]],
          link: this.getCatalogLink(undefined, undefined, true, this.periodSelected[CARD_NAME.DYNAMICS]),
          requestFunc: [
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.DYNAMICS],
                type: 'df'
              }
            }
          ],
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.DYNAMICS], this.tagsDays),
          tags: this.tagsDays,
          periodSelected: this.periodSelected[CARD_NAME.DYNAMICS],
          parent: [CARD_GROUP_NAME.PROJECT_SUMMARY]
        },
        [CARD_NAME.LEADERS_SALES_TOP3]: {
          id: CARD_NAME.LEADERS_SALES_TOP3,
          title: 'Лидеры продаж',
          load: this.preloader.itemsDefault,
          link: this.getCatalogLink('transactions_diff', 'DESC', true),
          type: 'table',
          items: this.leadersSalesTop3,
          columns: [{id: 'transactions', name: 'Транзакции'}],
          class: '_h-304',
          hasData: !!this.leadersSalesTop3.length,
          requestFunc: requestFuncConfig.requestDataPeriodStaticItems,
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.OUTSIDERS_SALES]: {
          id: CARD_NAME.OUTSIDERS_SALES,
          title: 'Аутсайдеры продаж',
          load: this.preloader.itemsDefault,
          link: this.getCatalogLink('transactions_diff', 'ASC', true),
          type: 'table',
          items: this.outsidersSales,
          columns: [{id: 'transactions', name: 'Транзакции'}],
          class: '_h-304',
          hasData: !!this.outsidersSales.length,
          requestFunc: requestFuncConfig.requestDataPeriodStaticItems,
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.OUTSIDERS, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.GEO_DEMAND]: {
          id: CARD_NAME.GEO_DEMAND,
          title: 'Спрос по гео',
          description: 'Поисковый спрос по гео в Яндекс за последние 30 дней',
          load: this.preloader.summaryGeo,
          link: this.getCatalogLink(),
          type: 'chart-pie',
          items: this.summaryGeoWithoutProjectSummary,
          metric: 'ws2',
          hasData: !!this.summaryGeoWithoutProjectSummary.length,
          class: '_h-505',
          requestFunc: requestFuncConfig.requestDataGeoSummary,
          parent: [CARD_GROUP_NAME.DEMAND_VISIBILITY, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_TRAFFIC]: {
          id: CARD_NAME.GEO_TRAFFIC,
          title: 'Трафик по гео',
          description: 'Трафик по гео за последние 30 дней',
          load: this.preloader.summaryGeo,
          link: this.getCatalogLink(),
          type: 'chart-pie',
          items: this.summaryGeoWithoutProjectSummary,
          metric: 'count_all',
          hasData: !!this.summaryGeoWithoutProjectSummary.length,
          class: '_h-505',
          requestFunc: requestFuncConfig.requestDataGeoSummary,
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_SALES]: {
          id: CARD_NAME.GEO_SALES,
          title: 'Продажи по гео',
          description: 'Продажи по гео за последние 30 дней',
          load: this.preloader.summaryGeo,
          link: this.getCatalogLink(),
          type: 'chart-pie',
          items: this.summaryGeoWithoutProjectSummary,
          metric: 'transactions',
          hasData: !!this.summaryGeoWithoutProjectSummary.length,
          class: '_h-505',
          requestFunc: requestFuncConfig.requestDataGeoSummary,
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_CR]: {
          id: CARD_NAME.GEO_CR,
          title: 'Конверсия по гео',
          description: 'Конверсия по гео за последние 30 дней',
          load: this.preloader.summaryGeo || this.preloader.summaryDefault,
          link: this.getCatalogLink(undefined, undefined, true),
          type: 'chart-column',
          items: this.summaryGeo(),
          metric: 'conversions',
          hasData: !!this.summaryGeo().length,
          requestFunc: requestFuncConfig.requestDataGeoSummaryFull,
          parent: [CARD_GROUP_NAME.CONVERSION, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_SUMMARY]: {
          id: CARD_NAME.GEO_SUMMARY,
          title: 'Метрики в разрезе гео',
          description: 'Данные по метрикам в разрезе гео за последние 30 дней',
          load: this.preloader.summaryGeo,
          link: this.getCatalogLink(),
          type: 'table',
          items: this.summaryGeoWithoutProjectSummary,
          columns: this.summaryGeoMetrics.filter(({id}) => id !== 'ptraf' && id !== 'transaction_revenue'),
          hasData: !!this.summaryGeo().length,
          geo: true,
          requestFunc: requestFuncConfig.requestDataGeoSummary,
          parent: [CARD_GROUP_NAME.PROJECT_SUMMARY, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_TRAFFIC_TOP5]: {
          id: CARD_NAME.GEO_TRAFFIC_TOP5,
          title: 'Лидеры трафика в разрезе гео',
          description: 'Трафик за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryTopGeo.sessions || this.preloader.itemsTopGeo.sessions,
          link: this.getCatalogLink('sessions', 'DESC'),
          type: 'table-geo',
          requestFunc: requestFuncConfig.requestDataTopGeoFull('sessions'),
          items: this.geoTrafficTop5,
          metric: {id: 'count_all', name: 'Трафик'},
          class: this.getClassByMetric('sessions'),
          columns: this.top5RegionsByMetric('sessions'),
          hasData: !!this.geoTrafficTop5.length,
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_TRANSACTION_REVENUE_TOP5]: {
          id: CARD_NAME.GEO_TRANSACTION_REVENUE_TOP5,
          title: 'Лидеры по выручке GA в разрезе гео',
          description: 'Выручка (GA) за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryTopGeo.transaction_revenue || this.preloader.itemsTopGeo.transaction_revenue,
          link: this.getCatalogLink('transaction_revenue', 'DESC'),
          type: 'table-geo',
          requestFunc: requestFuncConfig.requestDataTopGeoFull('transaction_revenue'),
          items: this.geoTransactionRevenueTop5,
          metric: {id: 'transaction_revenue', name: 'Выручка (GA)'},
          class: this.getClassByMetric('transaction_revenue'),
          columns: this.top5RegionsByMetric('transaction_revenue'),
          hasData: !!this.geoTransactionRevenueTop5.length,
          parent: [CARD_GROUP_NAME.REVENUE, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_SALES_TOP5]: {
          id: CARD_NAME.GEO_SALES_TOP5,
          title: 'Лидеры продаж в разрезе гео',
          description: 'Продажи за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryTopGeo.transactions || this.preloader.itemsTopGeo.transactions,
          link: this.getCatalogLink('transactions', 'DESC'),
          type: 'table-geo',
          requestFunc: requestFuncConfig.requestDataTopGeoFull('transactions'),
          items: this.geoSalesTop5,
          metric: {id: 'transactions', name: 'Транзакции (GA)'},
          class: this.getClassByMetric('transactions'),
          columns: this.top5RegionsByMetric('transactions'),
          hasData: !!this.geoSalesTop5.length,
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_CR_TOP5]: {
          id: CARD_NAME.GEO_CR_TOP5,
          title: 'Лидеры конверсии в разрезе гео',
          description: 'Конверсия за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryTopGeo.conversions || this.preloader.itemsTopGeo.conversions,
          link: this.getCatalogLink('conversions', 'DESC'),
          type: 'table-geo',
          requestFunc: requestFuncConfig.requestDataTopGeoFull('conversions'),
          items: this.geoCRTop5,
          metric: {id: 'conversions', name: '%CR', percent: true},
          class: this.getClassByMetric('conversions'),
          columns: this.top5RegionsByMetric('conversions'),
          hasData: !!this.geoCRTop5.length,
          parent: [CARD_GROUP_NAME.CONVERSION, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_LEADERS_SALES_TOP3]: {
          id: CARD_NAME.GEO_LEADERS_SALES_TOP3,
          title: 'ТОП 3 продаж в разрезе гео',
          description: 'Данные месяц к месяцу',
          load: this.preloader.itemsDefault || this.preloader.itemsTopGeo.transactions,
          link: this.getCatalogLink('transactions_diff', 'DESC', true, undefined, false, true),
          type: 'table-geo',
          items: this.geoLeadersSalesTop3,
          metric: {id: 'transactions'},
          class: `_h-354 ${this.getClassByMetric('transactions')}`,
          hasData: !!this.geoLeadersSalesTop3.length,
          requestFunc: requestFuncConfig.requestDataTopGeoItemsFull('transactions'),
          columns: this.top5RegionsByMetric('transactions'),
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_OUTSIDERS_SALES]: {
          id: CARD_NAME.GEO_OUTSIDERS_SALES,
          title: 'Аутсайдеры продаж в разрезе гео',
          description: 'Данные месяц к месяцу',
          load: this.preloader.itemsDefault || this.preloader.itemsTopGeo.transactions,
          link: this.getCatalogLink('transactions_diff', 'ASC', true, undefined, false, true),
          type: 'table-geo',
          items: this.geoOutsidersSales,
          metric: {id: 'transactions'},
          class: `_h-354 ${this.getClassByMetric('transactions')}`,
          hasData: !!this.geoOutsidersSales.length,
          requestFunc: requestFuncConfig.requestDataTopGeoItemsFull('transactions'),
          columns: this.top5RegionsByMetric('transactions'),
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.OUTSIDERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_LEADERS_CR_TOP3]: {
          id: CARD_NAME.GEO_LEADERS_CR_TOP3,
          title: 'ТОП 3 рост %CR в разрезе гео',
          description: 'Данные месяц к месяцу',
          load: this.preloader.itemsDefault || this.preloader.itemsTopGeo.conversions,
          link: this.getCatalogLink('transactions_diff', 'DESC', true, undefined, false, true),
          type: 'table-geo',
          items: this.geoLeadersCRTop3,
          metric: {id: 'conversions', percent: true},
          class: `_h-354 ${this.getClassByMetric('conversions')}`,
          hasData: !!this.geoLeadersCRTop3.length,
          requestFunc: requestFuncConfig.requestDataTopGeoItemsFull('conversions'),
          columns: this.top5RegionsByMetric('conversions'),
          parent: [CARD_GROUP_NAME.CONVERSION, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_OUTSIDERS_CR]: {
          id: CARD_NAME.GEO_OUTSIDERS_CR,
          title: 'ТОП 3 снижения %CR в разрезе гео',
          description: 'Данные месяц к месяцу',
          load: this.preloader.itemsDefault || this.preloader.itemsTopGeo.conversions,
          link: this.getCatalogLink('conversions_diff', 'ASC', true, undefined, false, true),
          type: 'table-geo',
          items: this.geoOutsidersCR,
          metric: {id: 'conversions', percent: true},
          class: `_h-354 ${this.getClassByMetric('conversions')}`,
          hasData: !!this.geoOutsidersCR.length,
          requestFunc: requestFuncConfig.requestDataTopGeoItemsFull('conversions'),
          columns: this.top5RegionsByMetric('conversions'),
          parent: [CARD_GROUP_NAME.CONVERSION, CARD_GROUP_NAME.OUTSIDERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_LEADERS_TRAFFIC_TOP3]: {
          id: CARD_NAME.GEO_LEADERS_TRAFFIC_TOP3,
          title: 'ТОП 3 рост трафика в разрезе гео',
          description: 'Данные месяц к месяцу',
          load: this.preloader.itemsDefault || this.preloader.itemsTopGeo.sessions,
          link: this.getCatalogLink('sessions_diff', 'DESC', true, undefined, false, true),
          type: 'table-geo',
          items: this.geoLeadersTrafficTop3,
          metric: {id: 'count_all'},
          class: `_h-354 ${this.getClassByMetric('sessions')}`,
          hasData: !!this.geoLeadersTrafficTop3.length,
          requestFunc: requestFuncConfig.requestDataTopGeoItemsFull('sessions'),
          columns: this.top5RegionsByMetric('sessions'),
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_OUTSIDERS_TRAFFIC]: {
          id: CARD_NAME.GEO_OUTSIDERS_TRAFFIC,
          title: 'ТОП 3 снижения трафика в разрезе гео',
          description: 'Данные месяц к месяцу',
          load: this.preloader.itemsDefault || this.preloader.itemsTopGeo.sessions,
          link: this.getCatalogLink('sessions_diff', 'ASC', true, undefined, false, true),
          type: 'table-geo',
          items: this.geoOutsidersTraffic,
          metric: {id: 'count_all'},
          class: `_h-354 ${this.getClassByMetric('sessions')}`,
          hasData: !!this.geoOutsidersTraffic.length,
          requestFunc: requestFuncConfig.requestDataTopGeoItemsFull('sessions'),
          columns: this.top5RegionsByMetric('sessions'),
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.OUTSIDERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_LEADERS_DEMAND]: {
          id: CARD_NAME.GEO_LEADERS_DEMAND,
          title: 'ТОП 3 рост спроса в разрезе гео',
          description: 'Данные месяц к месяцу',
          load: this.preloader.itemsDefault || this.preloader.itemsGeo,
          link: this.getCatalogLink('ws2_diff', 'DESC', true, undefined, false, true),
          type: 'table-geo',
          items: this.geoLeadersDemand,
          metric: {id: 'ws2'},
          class: '_h-354',
          hasData: !!this.geoLeadersDemand.length,
          requestFunc: requestFuncConfig.requestDataGeoItemsFull,
          columns: this.regions,
          parent: [CARD_GROUP_NAME.DEMAND_VISIBILITY, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.GEO_OUTSIDERS_DEMAND]: {
          id: CARD_NAME.GEO_OUTSIDERS_DEMAND,
          title: 'ТОП 3 снижения спроса в разрезе гео',
          description: 'Данные месяц к месяцу',
          load: this.preloader.itemsDefault || this.preloader.itemsGeo,
          link: this.getCatalogLink('ws2_diff', 'ASC', true, undefined, false, true),
          type: 'table-geo',
          items: this.geoOutsidersDemand,
          metric: {id: 'ws2'},
          class: '_h-354',
          hasData: !!this.geoOutsidersDemand.length,
          requestFunc: requestFuncConfig.requestDataGeoItemsFull,
          columns: this.regions,
          parent: [CARD_GROUP_NAME.DEMAND_VISIBILITY, CARD_GROUP_NAME.OUTSIDERS, CARD_GROUP_NAME.GEO]
        },
        [CARD_NAME.TRAFFIC_CHANNELS_TOP5]: {
          id: CARD_NAME.TRAFFIC_CHANNELS_TOP5,
          title: 'ТОП 5 категорий по трафику в разрезе каналов',
          description: 'Трафик за последние 30 дней в разрезе каналов',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryChannels || this.preloader.itemsChannels,
          link: this.getCatalogLink('sessions', 'DESC'),
          requestFunc: requestFuncConfig.requestDataPeriodStaticChannels,
          items: this.trafficChannelsTop5,
          metric: {id: 'count_all', name: 'Трафик'},
          hasData: !!this.trafficChannelsTop5.length,
          class: '_w-100',
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CHANNELS],
          type: 'table-channels',
          columns: this.filtersTrafficMedium.items
        },
        [CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]: {
          id: CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS,
          title: 'Лидеры категорий по динамике трафика в разрезе каналов',
          load: this.preloader.itemsPeriod[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]] || this.preloader.itemsChannelsPeriod[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]] || this.preloader.summaryChannelsPeriod.df[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]] || this.preloader.summaryChannelsPeriod.ga[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]] || this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]] || this.preloader.summaryPeriod.ga[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]],
          link: this.getCatalogLink('sessions_diff', 'DESC', true, this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]),
          items: this.trafficChannelsTop3Leaders,
          metric: {id: 'count_all'},
          columns: this.filtersTrafficMedium.items,
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS],
          component: 'card-channels-diff',
          requestFunc: requestFuncConfig.requestDataPeriodDynamicChannels(this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]),
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CHANNELS]
        },
        [CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]: {
          id: CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS,
          title: 'Аутсайдеры категорий по динамике трафика в разрезе каналов',
          load: this.preloader.itemsPeriod[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]] || this.preloader.itemsChannelsPeriod[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]] || this.preloader.summaryChannelsPeriod.df[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]] || this.preloader.summaryChannelsPeriod.ga[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]] || this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]] || this.preloader.summaryPeriod.ga[this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]],
          link: this.getCatalogLink('sessions_diff', 'ASC', true, this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]),
          items: this.trafficChannelsTop3Outsiders,
          metric: {id: 'count_all'},
          columns: this.filtersTrafficMedium.items,
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS],
          component: 'card-channels-diff',
          requestFunc: requestFuncConfig.requestDataPeriodDynamicChannels(this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]),
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.OUTSIDERS, CARD_GROUP_NAME.CHANNELS]
        },
        [CARD_NAME.ENTITY_METRIC_SUMMARY]: {
          id: CARD_NAME.ENTITY_METRIC_SUMMARY,
          title: 'Типы страниц. Динамика ключевых метрик',
          load: this.preloader.entitiesSummaryPeriod[this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY]] || this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY]] || this.preloader.summaryPeriod.ga[this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY]],
          link: this.getCatalogLink(undefined, undefined, true, this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY]),
          items: this.summaryEntitiesPeriod(this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY]),
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY],
          component: 'card-entity-diff',
          requestFunc: [
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY],
                type: 'df'
              }
            },
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY],
                type: 'ga'
              }
            },
            {
              requestName: 'requestDataEntity',
              requestConfig: {
                name: 'entitiesSummaryPeriod',
                periodSelected: this.periodSelected[CARD_NAME.ENTITY_METRIC_SUMMARY]
              }
            }
          ],
          columns: this.summaryEntityMetrics,
          parent: [CARD_GROUP_NAME.PROJECT_SUMMARY, CARD_GROUP_NAME.URL_TYPES]
        },
        [CARD_NAME.ENTITY_CHANNELS_TRAFFIC]: {
          id: CARD_NAME.ENTITY_CHANNELS_TRAFFIC,
          title: 'Типы страниц. Динамика трафика в разрезе каналов',
          load: this.preloader.entitiesSummaryPeriod[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC]] || this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC]] || this.preloader.summaryPeriod.ga[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC]] || this.preloader.summaryChannelsPeriod.df[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC]] || this.preloader.summaryChannelsPeriod.ga[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC]] || this.preloader.entitiesChannelsPeriod[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC]],
          link: this.getCatalogLink('sessions_diff', 'DESC', true, this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC]),
          items: this.entitiesChannelsPeriod(this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC], 'count_all'),
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC],
          component: 'card-entity-channels-diff',
          requestFunc: requestFuncConfig.requestDataPeriodDynamicEntitiesChannels(this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRAFFIC]),
          metric: {id: 'count_all'},
          columns: this.filtersTrafficMedium.items,
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.CHANNELS]
        },
        [CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]: {
          id: CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE,
          title: 'Типы страниц. Динамика выручки в разрезе каналов',
          load: this.preloader.entitiesSummaryPeriod[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]] || this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]] || this.preloader.summaryPeriod.ga[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]] || this.preloader.summaryChannelsPeriod.df[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]] || this.preloader.summaryChannelsPeriod.ga[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]] || this.preloader.entitiesChannelsPeriod[this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]],
          link: this.getCatalogLink('transaction_revenue_diff', 'DESC', true, this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]),
          items: this.entitiesChannelsPeriod(this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE], 'transaction_revenue'),
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE],
          component: 'card-entity-channels-diff',
          requestFunc: requestFuncConfig.requestDataPeriodDynamicEntitiesChannels(this.periodSelected[CARD_NAME.ENTITY_CHANNELS_TRANSACTION_REVENUE]),
          metric: {id: 'transaction_revenue'},
          columns: this.filtersTrafficMedium.items,
          parent: [CARD_GROUP_NAME.REVENUE, CARD_GROUP_NAME.CHANNELS]
        },
        [CARD_NAME.LEADERS_SALES_ECOMM]: {
          id: CARD_NAME.LEADERS_SALES_ECOMM,
          title: 'ТОП 5 категорий по продажам товаров и выручке',
          description: 'Продажи и выручка за последние 30 дней',
          load: this.preloader.itemsEcomDefault || this.preloader.summaryEcomDefault,
          link: this.getCatalogLink('item_revenue', 'DESC', false, undefined, true),
          type: 'table',
          items: this.leadersSalesEcomm,
          columns: [
            {id: 'item_quantity', name: 'Куплено товаров'},
            {id: 'item_revenue', name: 'Выручка'}
          ],
          hasData: !!this.leadersSalesEcomm.length,
          ecomm: true,
          requestFunc: [
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {
                name: 'itemsEcomDefault'
              }
            },
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {
                name: 'summaryEcomDefault',
                summary: true
              }
            }
          ],
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS]: {
          id: CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS,
          title: 'Лидеры категорий по динамике выручки (Ecommerce)',
          load: this.preloader.itemsEcomPeriod[this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS]] || this.preloader.itemsEcomChannelsPeriod[this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS]] || this.preloader.summaryEcomChannelsPeriod.df[this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS]] || this.preloader.summaryEcomPeriod.df[this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS]],
          link: this.getCatalogLink('item_revenue_diff', 'DESC', true, this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS], true),
          items: this.ecommChannelsTop3Leaders,
          metric: {id: 'item_revenue'},
          columns: this.filtersTrafficMedium.items,
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS],
          component: 'card-channels-diff',
          ecomm: true,
          requestFunc: requestFuncConfig.requestDataPeriodDynamicEcom(this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS]),
          parent: [CARD_GROUP_NAME.REVENUE, CARD_GROUP_NAME.LEADERS, CARD_GROUP_NAME.CHANNELS]
        },
        [CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS]: {
          id: CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS,
          title: 'Аутсайдеры категорий по динамике выручки (Ecommerce)',
          load: this.preloader.itemsEcomPeriod[this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS]] || this.preloader.itemsEcomChannelsPeriod[this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS]] || this.preloader.summaryEcomChannelsPeriod.df[this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS]] || this.preloader.summaryEcomPeriod.df[this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS]],
          link: this.getCatalogLink('item_revenue_diff', 'ASC', true, this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS], true),
          items: this.ecommChannelsTop3Outsiders,
          metric: {id: 'item_revenue'},
          columns: this.filtersTrafficMedium.items,
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS],
          component: 'card-channels-diff',
          ecomm: true,
          requestFunc: requestFuncConfig.requestDataPeriodDynamicEcom(this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS]),
          parent: [CARD_GROUP_NAME.REVENUE, CARD_GROUP_NAME.OUTSIDERS, CARD_GROUP_NAME.CHANNELS]
        },
        [CARD_NAME.DEVICE_CHANNELS_TRAFFIC]: {
          id: CARD_NAME.DEVICE_CHANNELS_TRAFFIC,
          title: 'Типы устройств. Динамика трафика в разрезе каналов',
          load: this.preloader.devicesSummaryPeriod[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]] || this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]] || this.preloader.summaryPeriod.ga[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]] || this.preloader.summaryChannelsPeriod.df[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]] || this.preloader.summaryChannelsPeriod.ga[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]] || this.preloader.devicesChannelsPeriod[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]],
          link: this.getCatalogLink('sessions_diff', 'DESC', true, this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]),
          items: this.entitiesChannelsPeriod(this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC], 'count_all', true),
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC],
          component: 'card-entity-channels-diff',
          requestFunc: [
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC],
                type: 'df'
              }
            },
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC],
                type: 'ga'
              }
            },
            {
              requestName: 'requestDataEntity',
              requestConfig: {
                name: 'devicesSummaryPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]
              }
            },
            {
              requestName: 'requestDataEntityChannelsPeriodDynamic',
              requestConfig: {
                name: 'devicesChannelsPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC]
              }
            },
            {
              requestName: 'requestDataChannelsPeriodDynamic',
              requestConfig: {
                name: 'summaryChannelsPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC],
                summary: 1,
                type: 'df'
              }
            },
            {
              requestName: 'requestDataChannelsPeriodDynamic',
              requestConfig: {
                name: 'summaryChannelsPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_TRAFFIC],
                summary: 1,
                type: 'ga'
              }
            }
          ],
          metric: {id: 'count_all'},
          columns: this.filtersTrafficMedium.items,
          device: true,
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.CHANNELS]
        },
        [CARD_NAME.DEVICE_CHANNELS_REVENUE]: {
          id: CARD_NAME.DEVICE_CHANNELS_REVENUE,
          title: 'Типы устройств. Динамика выручки в разрезе каналов',
          load: this.preloader.devicesSummaryPeriod[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]] || this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]] || this.preloader.summaryPeriod.ga[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]] || this.preloader.summaryChannelsPeriod.df[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]] || this.preloader.summaryChannelsPeriod.ga[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]] || this.preloader.devicesChannelsPeriod[this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]],
          link: this.getCatalogLink('transaction_revenue_diff', 'DESC', true, this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]),
          items: this.entitiesChannelsPeriod(this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE], 'transaction_revenue', true),
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE],
          component: 'card-entity-channels-diff',
          requestFunc: [
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE],
                type: 'df'
              }
            },
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE],
                type: 'ga'
              }
            },
            {
              requestName: 'requestDataEntity',
              requestConfig: {
                name: 'devicesSummaryPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]
              }
            },
            {
              requestName: 'requestDataEntityChannelsPeriodDynamic',
              requestConfig: {
                name: 'devicesChannelsPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE]
              }
            },
            {
              requestName: 'requestDataChannelsPeriodDynamic',
              requestConfig: {
                name: 'summaryChannelsPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE],
                summary: 1,
                type: 'df'
              }
            },
            {
              requestName: 'requestDataChannelsPeriodDynamic',
              requestConfig: {
                name: 'summaryChannelsPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_CHANNELS_REVENUE],
                summary: 1,
                type: 'ga'
              }
            }
          ],
          metric: {id: 'transaction_revenue'},
          columns: this.filtersTrafficMedium.items,
          device: true,
          parent: [CARD_GROUP_NAME.REVENUE, CARD_GROUP_NAME.CHANNELS]
        },
        [CARD_NAME.DEVICE_METRIC_SUMMARY]: {
          id: CARD_NAME.DEVICE_METRIC_SUMMARY,
          title: 'Типы устройств. Динамика ключевых метрик проекта',
          load: this.preloader.devicesSummaryPeriod[this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY]] || this.preloader.summaryPeriod.df[this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY]] || this.preloader.summaryPeriod.ga[this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY]],
          link: this.getCatalogLink(undefined, undefined, true, this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY]),
          items: this.summaryEntitiesPeriod(this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY], true),
          label: this.getDescriptionLabel(this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY], this.tagsPeriod),
          tags: this.tagsPeriod,
          periodSelected: this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY],
          component: 'card-entity-diff',
          device: true,
          requestFunc: [
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY],
                type: 'df'
              }
            },
            {
              requestName: 'requestDataPeriodDynamic',
              requestConfig: {
                name: 'summaryPeriod',
                summary: true,
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY],
                type: 'ga'
              }
            },
            {
              requestName: 'requestDataEntity',
              requestConfig: {
                name: 'devicesSummaryPeriod',
                periodSelected: this.periodSelected[CARD_NAME.DEVICE_METRIC_SUMMARY]
              }
            }
          ],
          columns: this.summaryEntityMetrics,
          parent: [CARD_GROUP_NAME.PROJECT_SUMMARY, CARD_GROUP_NAME.DEVICES]
        },
        [CARD_NAME.DEVICE_TRAFFIC_TOP5]: {
          id: CARD_NAME.DEVICE_TRAFFIC_TOP5,
          title: 'Лидеры по трафику в разрезе типов устройств',
          description: 'Трафик за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryDevices || this.preloader.itemsDevices,
          link: this.getCatalogLink('sessions', 'DESC'),
          type: 'table-device',
          requestFunc: [
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {name: 'itemsDefault'}
            },
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {
                name: 'summaryDefault',
                summary: true
              }
            },
            {
              requestName: 'requestDataDevices',
              requestConfig: {
                name: 'summaryDevices',
                summary: 1
              }
            },
            {
              requestName: 'requestDataDevices',
              requestConfig: {name: 'itemsDevices'}
            }
          ],
          items: this.deviceTrafficTop5,
          metric: {id: 'count_all', name: 'Трафик'},
          columns: this.filtersDevices,
          hasData: !!this.deviceTrafficTop5.length,
          parent: [CARD_GROUP_NAME.TRAFFIC, CARD_GROUP_NAME.DEVICES, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.DEVICE_TRANSACTION_REVENUE_TOP5]: {
          id: CARD_NAME.DEVICE_TRANSACTION_REVENUE_TOP5,
          title: 'Лидеры по выручке GA в разрезе типов устройств',
          description: 'Выручка (GA) за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryDevices || this.preloader.itemsDevices,
          link: this.getCatalogLink('transaction_revenue', 'DESC'),
          type: 'table-device',
          requestFunc: [
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {name: 'itemsDefault'}
            },
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {
                name: 'summaryDefault',
                summary: true
              }
            },
            {
              requestName: 'requestDataDevices',
              requestConfig: {
                name: 'summaryDevices',
                summary: 1
              }
            },
            {
              requestName: 'requestDataDevices',
              requestConfig: {name: 'itemsDevices'}
            }
          ],
          items: this.deviceTransactionRevenueTop5,
          metric: {id: 'transaction_revenue', name: 'Выручка (GA)'},
          columns: this.filtersDevices,
          hasData: !!this.deviceTransactionRevenueTop5.length,
          parent: [CARD_GROUP_NAME.REVENUE, CARD_GROUP_NAME.DEVICES, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.DEVICE_SALES_TOP5]: {
          id: CARD_NAME.DEVICE_SALES_TOP5,
          title: 'Лидеры по транзакциям в разрезе типов устройств',
          description: 'Транзакции за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryDevices || this.preloader.itemsDevices,
          link: this.getCatalogLink('transactions', 'DESC'),
          type: 'table-device',
          requestFunc: [
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {name: 'itemsDefault'}
            },
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {
                name: 'summaryDefault',
                summary: true
              }
            },
            {
              requestName: 'requestDataDevices',
              requestConfig: {
                name: 'summaryDevices',
                summary: 1
              }
            },
            {
              requestName: 'requestDataDevices',
              requestConfig: {name: 'itemsDevices'}
            }
          ],
          items: this.deviceSalesTop5,
          metric: {id: 'transactions', name: 'Транзакции (GA)'},
          columns: this.filtersDevices,
          hasData: !!this.deviceSalesTop5.length,
          parent: [CARD_GROUP_NAME.TRANSACTIONS, CARD_GROUP_NAME.DEVICES, CARD_GROUP_NAME.CATEGORIES]
        },
        [CARD_NAME.DEVICE_CR_TOP5]: {
          id: CARD_NAME.DEVICE_CR_TOP5,
          title: 'Лидеры по %конверсии в разрезе типов устройств',
          description: 'Конверсия за последние 30 дней',
          load: this.preloader.itemsDefault || this.preloader.summaryDefault || this.preloader.summaryDevices || this.preloader.itemsDevices,
          link: this.getCatalogLink('conversions', 'DESC'),
          type: 'table-device',
          requestFunc: [
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {name: 'itemsDefault'}
            },
            {
              requestName: 'requestDataPeriodStatic',
              requestConfig: {
                name: 'summaryDefault',
                summary: true
              }
            },
            {
              requestName: 'requestDataDevices',
              requestConfig: {
                name: 'summaryDevices',
                summary: 1
              }
            },
            {
              requestName: 'requestDataDevices',
              requestConfig: {name: 'itemsDevices'}
            }
          ],
          items: this.deviceCRTop5,
          metric: {id: 'conversions', name: '%CR', percent: true},
          columns: this.filtersDevices,
          hasData: !!this.deviceCRTop5.length,
          parent: [CARD_GROUP_NAME.CONVERSION, CARD_GROUP_NAME.DEVICES, CARD_GROUP_NAME.CATEGORIES]
        }
      }
    },

    geoIds() {
      return this.regions.map(item => item.id)
    },

    tagsDays() {
      return this.getTags(TAG_LABELS.DAYS)
    },

    tagsPeriod() {
      return this.getTags(TAG_LABELS.PERIOD)
    },

    regions() {
      return this.filtersRegions(true)
    },

    needRegionsDataMetrics() {
      return this.topRegionsMetrics.filter((metric) => this.topRegions[metric.query].hasData === false)
    },

    leadersSalesTop5() {
      return this.metricData({
        name: 'transactions',
        summary: true
      })
    },

    leadersCRTop5() {
      return this.metricData({
        name: 'conversions',
        two: true,
        summary: true
      })
    },

    leadersTrafficTop5() {
      return this.metricData({
        name: 'count_all',
        summary: true
      })
    },

    demand() {
      return this.metricData({name: 'ws2'})
    },

    leadersSalesTop3() {
      return this.metricData({
        name: 'transactions',
        diff: true,
        count: 3,
        positive: true,
        two: true
      })
    },

    outsidersSales() {
      return this.metricData({
        name: 'transactions',
        diff: true,
        count: 3,
        negative: true,
        two: true,
        asc: true
      })
    },

    summaryGeoWithoutProjectSummary() {
      return this.summaryGeo().filter(({id}) => id !== 'sum')
    },

    geoTrafficTop5() {
      return this.metricDataGeo({
        name: 'count_all',
        summary: true,
        metric: 'sessions'
      })
    },

    geoTransactionRevenueTop5() {
      return this.metricDataGeo({
        name: 'transaction_revenue',
        summary: true,
        metric: 'transaction_revenue'
      })
    },

    geoSalesTop5() {
      return this.metricDataGeo({
        name: 'transactions',
        summary: true,
        metric: 'transactions'
      })
    },

    geoCRTop5() {
      return this.metricDataGeo({
        name: 'conversions',
        summary: true,
        metric: 'conversions'
      })
    },

    geoLeadersSalesTop3() {
      return this.metricDataGeo({
        name: 'transactions',
        diff: true,
        count: 3,
        positive: true,
        two: true,
        metric: 'transactions'
      })
    },

    geoOutsidersSales() {
      return this.metricDataGeo({
        name: 'transactions',
        diff: true,
        count: 3,
        negative: true,
        two: true,
        asc: true,
        metric: 'transactions'
      })
    },

    geoLeadersCRTop3() {
      return this.metricDataGeo({
        name: 'conversions',
        diff: true,
        count: 3,
        positive: true,
        two: true,
        metric: 'conversions'
      })
    },

    geoOutsidersCR() {
      return this.metricDataGeo({
        name: 'conversions',
        diff: true,
        count: 3,
        negative: true,
        two: true,
        asc: true,
        metric: 'conversions'
      })
    },

    geoLeadersTrafficTop3() {
      return this.metricDataGeo({
        name: 'count_all',
        diff: true,
        count: 3,
        positive: true,
        two: true,
        metric: 'sessions'
      })
    },

    geoOutsidersTraffic() {
      return this.metricDataGeo({
        name: 'count_all',
        diff: true,
        count: 3,
        negative: true,
        two: true,
        asc: true,
        metric: 'sessions'
      })
    },

    geoLeadersDemand() {
      return this.metricDataGeo({
        name: 'ws2',
        diff: true,
        count: 3,
        positive: true,
        two: true
      })
    },

    geoOutsidersDemand() {
      return this.metricDataGeo({
        name: 'ws2',
        diff: true,
        count: 3,
        negative: true,
        two: true,
        asc: true
      })
    },

    trafficChannelsTop5() {
      return this.metricDataChannels({
        name: 'count_all',
        summary: true
      })
    },

    trafficChannelsTop3Leaders() {
      return this.metricDataChannelsPeriod({
        name: 'count_all',
        summary: true,
        diff: true,
        count: 3,
        positive: true,
        two: true,
        period: this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_LEADERS]
      })
    },

    trafficChannelsTop3Outsiders() {
      return this.metricDataChannelsPeriod({
        name: 'count_all',
        summary: true,
        diff: true,
        count: 3,
        negative: true,
        two: true,
        asc: true,
        period: this.periodSelected[CARD_NAME.TRAFFIC_CHANNELS_TOP3_OUTSIDERS]
      })
    },

    leadersSalesEcomm() {
      return this.metricsData({
        metrics: ['item_quantity', 'item_revenue'],
        summary: true,
        sortBy: 'item_revenue'
      })
    },

    ecommChannelsTop3Leaders() {
      return this.metricDataChannelsPeriod({
        name: 'item_revenue',
        summary: true,
        diff: true,
        count: 3,
        positive: true,
        two: true,
        period: this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_LEADERS],
        ecom: true
      })
    },

    ecommChannelsTop3Outsiders() {
      return this.metricDataChannelsPeriod({
        name: 'item_revenue',
        summary: true,
        diff: true,
        count: 3,
        negative: true,
        two: true,
        asc: true,
        period: this.periodSelected[CARD_NAME.ECOMM_CHANNELS_TOP3_OUTSIDERS],
        ecom: true
      })
    },

    deviceTrafficTop5() {
      return this.metricDataDevices({
        name: 'count_all',
        summary: true
      })
    },

    deviceTransactionRevenueTop5() {
      return this.metricDataDevices({
        name: 'transaction_revenue',
        summary: true
      })
    },

    deviceSalesTop5() {
      return this.metricDataDevices({
        name: 'transactions',
        summary: true
      })
    },

    deviceCRTop5() {
      return this.metricDataDevices({
        name: 'conversions',
        summary: true
      })
    }
  },

  watch: {
    categories(val) {
      this.setColors(val)
    },

    cardList(val, from) {
      if (!val.every(item => findOptions(item.id, from))) {
        this.initData(!from.length)
      }

      let newConfig = [...val]

      this.widgetConfig.data.forEach(item => {
        if (!findOptions(item.id, newConfig)) {
          newConfig.push(item)
        }
      })

      this.saveWidgetConfig({
        hasData: true,
        projectId: this.activeProjectId,
        data: newConfig
      })
    }
  },

  async created() {
    this.setCancelRequests(false)
    this.clearData()

    await this.requestWidgetConfig()

    if (this.widgetConfig.data.length) {
      this.setWidgetConfig(this.widgetConfig.data)
    } else {
      await this.initWidgetConfig()
    }

    this.availableColors = [...this.chartColors]
  },

  destroyed() {
    this.saveWidgetConfig({hasData: false})
    this.setCancelRequests(true)
  },

  methods: {
    ...mapMutations({
      saveFilters: 'saveFilters',
      setCancelRequests: 'setCancelRequests',
      saveDataChannels: 'dashboard/quickStart/saveDataChannels',
      clearFiltersTraffic: 'clearFiltersTraffic',
      saveWidgetConfig: 'dashboard/quickStart/saveWidgetConfig',
      resetState: 'dashboard/quickStart/resetState'
    }),

    ...mapActions({
      getDataPeriodStatic: 'dashboard/quickStart/getDataPeriodStatic',
      getDataPeriodDynamic: 'dashboard/quickStart/getDataPeriodDynamic',
      getDataGeo: 'dashboard/quickStart/getDataGeo',
      getDataTopGeo: 'dashboard/quickStart/getDataTopGeo',
      getDataChannels: 'dashboard/quickStart/getDataChannels',
      getDataChannelsPeriodDynamic: 'dashboard/quickStart/getDataChannelsPeriodDynamic',
      getFilters: 'getFilters',
      getFiltersTraffic: 'getFiltersTraffic',
      getWidgetConfig: 'dashboard/quickStart/getWidgetConfig',
      getDataEntity: 'dashboard/quickStart/getDataEntity',
      getDataEntityChannelsPeriodDynamic: 'dashboard/quickStart/getDataEntityChannelsPeriodDynamic',
      updateWidgetConfig: 'dashboard/quickStart/updateWidgetConfig',
      getDataDevices: 'dashboard/quickStart/getDataDevices',
      getTopRegionsByMetric: 'dashboard/quickStart/getTopRegionsByMetric'
    }),

    getCatalogLink(order_by, order_direction, comparison, periodSelected, ecomm, calendar) {
      let query = {
        order_direction,
        order_by
      }

      if (periodSelected) {
        query = {
          ...query,
          ...this.getDates(periodSelected, true)
        }
      } else {
        if (comparison) {
          if (calendar) {
            query.period_one_date_from = this.calendarMonthCurrent.from
            query.period_one_date_to = this.calendarMonthCurrent.to
            query.period_two_date_from = this.calendarMonthPrev.from
            query.period_two_date_to = this.calendarMonthPrev.to
          } else {
            query.period_one_date_from = this.monthCurrent.from
            query.period_one_date_to = this.monthCurrent.to
            query.period_two_date_from = this.monthPrev.from
            query.period_two_date_to = this.monthPrev.to
          }
        } else {
          query.period_one_date_from = this.monthCurrent.from
          query.period_one_date_to = this.monthCurrent.to
        }
      }

      return {
        to: {
          name: ROUTE_NAME[ecomm ? 'COMMERCE_ANALYSIS' : 'INDEX_CATALOG'],
          params: {activeProject: this.activeProjectId},
          query
        }
      }
    },

    setColors(items) {
      let list = {...this.entityColors}

      for (let i of items) {
        if (!this.availableColors.length) {
          this.availableColors = [...this.chartColors]
        }

        if (!Object.keys(this.entityColors).includes(i)) {
          list[i] = this.availableColors[Math.floor(Math.random() * this.availableColors.length)]
          this.availableColors.splice(this.availableColors.indexOf(list[i]), 1)
        }
      }

      this.entityColors = list
    },

    setWidgetConfig(newConfig) {
      this.saveWidgetConfig({
        hasData: true,
        projectId: this.activeProjectId,
        data: newConfig
      })
      this.cardList = newConfig.filter(item => item.visible)
    },

    async initWidgetConfig() {
      const newConfig = this.getCardConfig()

      try {
        this.updateWidgetConfigDispatch(newConfig)
          .then(() => {
            this.setWidgetConfig(newConfig)
          })
      } catch ({message}) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
      }
    },

    async updateWidgetConfigDispatch(newConfig) {
      await this.updateWidgetConfig({
        query: {
          project_id: this.activeProjectId,
          skip_regionality: 1
        },
        body: newConfig
      })
    },

    async initData(first) {
      if (!this.filtersOptions.has_data) {
        try {
          let requests = [
            await this.getFilters({
            query: {
              project_id: this.activeProjectId,
              skip_regionality: 1,
              from_cache: 1,
              widget_request_id: 'options_dimensions'
            }
          }),
          await this.getFiltersTraffic({
            query: {
              project_id: this.activeProjectId,
              dimension: 'medium',
              skip_regionality: 1,
              date_from: this.monthCurrent.from,
              date_to: this.monthCurrent.to,
              limit: 5,
              from_cache: 1,
              widget_request_id: 'options_medium'
            }
          })]

          this.needRegionsDataMetrics.forEach((metric) => {
            requests.push(this.getTopRegionsByMetric({
              query: {
                project_id: this.activeProjectId,
                skip_regionality: 1,
                traffic_metric: metric.query,
                from_cache: 1,
                widget_request_id: `top_regions_${metric.query}`,
              },
              metric: metric.query
            }))
          })

          await Promise.all(requests)
        } catch (error) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: error.message})
        }
      }

      this.setColors(this.geoIds)

      let requests = []

      const cardOrderFiltered = this.widgetConfig.data.filter(card => card.visible && this.cardsConfig[card.id] && this.cardsConfig[card.id].requestFunc)

      for (let card of cardOrderFiltered) {
        this.cardsConfig[card.id].requestFunc.forEach(item => {
          requests.push(item)
        })
      }

      this.requestQueue = [...requests.map(item => {
        return async (callback) => {
          await this[item.requestName](item.requestConfig)
          if (callback) {
            callback()
          }
        }
      })]

      this.setCancelRequests(true)

      try {
        await parallelLimit(this.requestQueue, 2)
      } catch (error) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: error.message})
      }

      if (first) {
        this.setCancelRequests(false)
      }
    },

    clearData() {
      this.clearFiltersTraffic('medium')
      this.saveFilters({hasData: false})
      this.resetState()
    },

    getDates(type, calendar = false) {
      if (calendar) {
        switch (type) {
          case this.periodOptions.WEEK_TO_WEEK:
            return {
              period_one_date_from: this.calendarWeekCurrent.from,
              period_one_date_to: this.calendarWeekCurrent.to,
              period_two_date_from: this.calendarWeekPrev.from,
              period_two_date_to: this.calendarWeekPrev.to
            }
          case this.periodOptions.WEEK_TO_WEEK_PAST_YEAR:
            return {
              period_one_date_from: this.calendarWeekCurrent.from,
              period_one_date_to: this.calendarWeekCurrent.to,
              period_two_date_from: this.calendarWeekPrevYear.from,
              period_two_date_to: this.calendarWeekPrevYear.to
            }
          case this.periodOptions.MONTH_TO_MONTH:
            return {
              period_one_date_from: this.calendarMonthCurrent.from,
              period_one_date_to: this.calendarMonthCurrent.to,
              period_two_date_from: this.calendarMonthPrev.from,
              period_two_date_to: this.calendarMonthPrev.to
            }
          case this.periodOptions.MONTH_TO_MONTH_PAST_YEAR:
            return {
              period_one_date_from: this.calendarMonthCurrent.from,
              period_one_date_to: this.calendarMonthCurrent.to,
              period_two_date_from: this.calendarMonthPrevYear.from,
              period_two_date_to: this.calendarMonthPrevYear.to
            }
          case this.periodOptions.DAY_TO_DAY:
            return {
              period_one_date_from: this.dayCurrent.from,
              period_one_date_to: this.dayCurrent.to,
              period_two_date_from: this.dayPrev.from,
              period_two_date_to: this.dayPrev.to
            }
          case this.periodOptions.DAY_TO_DAY_PAST_WEEK:
            return {
              period_one_date_from: this.dayCurrent.from,
              period_one_date_to: this.dayCurrent.to,
              period_two_date_from: this.dayPrevWeek.from,
              period_two_date_to: this.dayPrevWeek.to
            }
        }
      } else {
        switch (type) {
          case this.periodOptions.WEEK_TO_WEEK:
            return {
              period_one_date_from: this.weekCurrent.from,
              period_one_date_to: this.weekCurrent.to,
              period_two_date_from: this.weekPrev.from,
              period_two_date_to: this.weekPrev.to
            }
          case this.periodOptions.WEEK_TO_WEEK_PAST_YEAR:
            return {
              period_one_date_from: this.weekCurrent.from,
              period_one_date_to: this.weekCurrent.to,
              period_two_date_from: this.weekPrevYear.from,
              period_two_date_to: this.weekPrevYear.to
            }
          case this.periodOptions.MONTH_TO_MONTH:
            return {
              period_one_date_from: this.monthCurrent.from,
              period_one_date_to: this.monthCurrent.to,
              period_two_date_from: this.monthPrev.from,
              period_two_date_to: this.monthPrev.to
            }
          case this.periodOptions.MONTH_TO_MONTH_PAST_YEAR:
            return {
              period_one_date_from: this.monthCurrent.from,
              period_one_date_to: this.monthCurrent.to,
              period_two_date_from: this.monthPrevYear.from,
              period_two_date_to: this.monthPrevYear.to
            }
          case this.periodOptions.DAY_TO_DAY:
            return {
              period_one_date_from: this.dayCurrent.from,
              period_one_date_to: this.dayCurrent.to,
              period_two_date_from: this.dayPrev.from,
              period_two_date_to: this.dayPrev.to
            }
          case this.periodOptions.DAY_TO_DAY_PAST_WEEK:
            return {
              period_one_date_from: this.dayCurrent.from,
              period_one_date_to: this.dayCurrent.to,
              period_two_date_from: this.dayPrevWeek.from,
              period_two_date_to: this.dayPrevWeek.to
            }
        }
      }
    },

    async selectPeriod({name, value}) {
      if (this.periodSelected[name] !== value) {
        await this.$set(this.periodSelected, name, value)

        for (let request of this.cardsConfig[name].requestFunc) {
          this[request.requestName](request.requestConfig)
        }
      }
    },

    getDescriptionLabel(type, tags) {
      const tag = findOptions(type, tags)

      if (tag && tag.description) return tag.description
      else return ''
    },

    getTags(dictionary) {
      return [
        {
          id: this.periodOptions.DAY_TO_DAY,
          name: 'Day to day',
          description: 'за вчерашний день (к позавчерашнему дню)'
        },
        {
          id: this.periodOptions.DAY_TO_DAY_PAST_WEEK,
          name: 'Day to day past week',
          description: 'за вчерашний день (к аналогичному периоду прошлой недели)'
        },
        {
          id: this.periodOptions.WEEK_TO_WEEK,
          name: 'Week to week',
          description: dictionary.WEEK_TO_WEEK
        },
        {
          id: this.periodOptions.WEEK_TO_WEEK_PAST_YEAR,
          name: 'Week to week past year',
          description: dictionary.WEEK_TO_WEEK_PAST_YEAR
        },
        {
          id: this.periodOptions.MONTH_TO_MONTH,
          name: 'Month to month',
          description: dictionary.MONTH_TO_MONTH
        },
        {
          id: this.periodOptions.MONTH_TO_MONTH_PAST_YEAR,
          name: 'Month to month past year',
          description: dictionary.MONTH_TO_MONTH_PAST_YEAR
        }
      ]
    },

    openSettings() {
      this.$modal.open({
        name: MODAL_TITLE.CARD_SETTINGS,
        props: {
          cards: this.cardsConfig,
          configName: CARD_CONFIG_NAME,
          cardConfig: this.widgetConfig.data,
          cardList: this.cardList,
          setCardListFunc: this.setCardList,
          updateWidgetConfigFunc: this.updateWidgetConfigDispatch
        }
      })
    },

    setCardList(data) {
      this.cardList = data
    },

    hideCard(cardId) {
      let newConfig = this.widgetConfig.data
      let idx = findKey(cardId, newConfig)
      newConfig[idx].visible = false

      try {
        this.updateWidgetConfigDispatch(newConfig)
          .then(() => {
            this.saveWidgetConfig({
              hasData: true,
              projectId: this.activeProjectId,
              data: newConfig
            })
          })
      } catch ({message}) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
      }

      this.cardList = newConfig.filter(item => item.visible)
    },

    moveCard() {
      try {
        this.updateWidgetConfigDispatch(this.widgetConfig.data)
          .then(() => {
            this.saveWidgetConfig({
              hasData: true,
              projectId: this.activeProjectId,
              data: this.widgetConfig.data
            })
          })
      } catch ({message}) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
      }
    },

    getCardConfig() {
      let cardsConfig = JSON.parse(localStorage.getItem(CARD_CONFIG_NAME))
      let newConfig = []

      if (cardsConfig) {
        if (typeof cardsConfig[0] === 'string') {
          cardsConfig = cardsConfig.map(item => {
            return {
              id: item,
              visible: true
            }
          })
        }

        newConfig = [...cardsConfig].filter(item => this.cardsConfig[item.id])
        for (let card in this.cardsConfig) {
          if (!findOptions(card, cardsConfig)) {
            newConfig.push({
              id: card,
              visible: true
            })
          }
        }
      } else {
        for (let card in this.cardsConfig) {
          newConfig.push({
            id: card,
            visible: true
          })
        }
      }

      return newConfig
    },

    async requestWidgetConfig() {
      if (!this.preloader.page) {
        this.preloader.page = true
      }
      try {
        await this.getWidgetConfig({
          query: {
            project_id: this.activeProjectId,
            skip_regionality: 1
          }
        })
      } catch ({message}) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
      } finally {
        this.preloader.page = false
      }
    },

    async requestDataPeriodStatic({summary, name}) {
      if (this.needsData[name]) {
        this.$set(this.needsData, name, false)

        if (!this.preloader[name]) {
          this.$set(this.preloader, name, true)
        }

        try {
          let query = {
            project_id: this.activeProjectId,
            catalog_id: '0',
            skip_regionality: 1,
            v2: 1,
            summary: summary ? 1 : undefined,
            limit: summary ? undefined : 1000,
            period_one_date_from: this.monthCurrent.from,
            period_one_date_to: this.monthCurrent.to,
            period_two_date_from: this.monthPrev.from,
            period_two_date_to: this.monthPrev.to,
            traffic_source: 'ga4',
            from_cache: 1,
            widget_request_id: name
          }

          await this.getDataPeriodStatic({name, query})
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          this.$set(this.preloader, name, false)
        }
      }
    },

    async requestDataPeriodDynamic({summary, periodSelected, name, type}) {
      const needsDataFlag = summary
        ? this.needsData[name][type][periodSelected]
        : this.needsData[name][periodSelected]
      const preloaderFlag = summary
        ? this.preloader[name][type][periodSelected]
        : this.preloader[name][periodSelected]
      if (needsDataFlag) {
        if (summary) {
          this.$set(this.needsData[name][type], periodSelected, false)
        } else {
          this.$set(this.needsData[name], periodSelected, false)
        }

        if (!preloaderFlag) {
          if (summary) {
            this.$set(this.preloader[name][type], periodSelected, true)
          } else {
            this.$set(this.preloader[name], periodSelected, true)
          }
        }

        const includesYesterday = this.isDayPeriod(periodSelected)

        let widget_request_id = `${name}_${periodSelected}`

        if (summary) {
          widget_request_id += `_${type}`
        }

        try {
          let query = {
            project_id: this.activeProjectId,
            catalog_id: '0',
            skip_regionality: 1,
            v2: 1,
            summary: summary ? 1 : undefined,
            limit: summary ? undefined : 1000,
            ...this.getDates(periodSelected, true),
            traffic_source: 'ga4',
            from_cache: includesYesterday ? undefined : 1,
            widget_request_id: includesYesterday ? undefined : widget_request_id,
            traffic_all: type === 'ga' ? 1 : 0
          }
          await this.getDataPeriodDynamic({name, query, periodSelected, type})

        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          if (summary) {
            this.$set(this.preloader[name][type], periodSelected, false)
          } else {
            this.$set(this.preloader[name], periodSelected, false)
          }
        }
      }
    },

    async requestDataGeo({summary, name}) {
      if (this.needsData[name]) {
        this.$set(this.needsData, name, false)

        if (!this.preloader[name]) {
          this.$set(this.preloader, name, true)
        }

        try {
          let query = {
            project_id: this.activeProjectId,
            catalog_id: '0',
            skip_regionality: 1,
            v2: 1,
            summary: summary ? 1 : undefined,
            limit: summary ? undefined : 1000,
            period_one_date_from: this.monthCurrent.from,
            period_one_date_to: this.monthCurrent.to,
            period_two_date_from: this.monthPrev.from,
            period_two_date_to: this.monthPrev.to,
            traffic_source: 'ga4',
            need_geo: 1,
            from_cache: 1
          }

          let requests = this.geoIds.map(id => {
            return async () => {
              await this.getDataGeo({
                name,
                query: {
                  ...query,
                  region_id: id,
                  widget_request_id: name + id
                }
              })
            }
          })
          await parallelLimit(requests, 2)
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          this.$set(this.preloader, name, false)
        }
      }
    },

    async requestDataTopGeo({summary, name}) {
      const {cacheId, metric} = name
      if (this.needsData[cacheId][metric]) {
        this.$set(this.needsData[cacheId], metric, false)

        if (!this.preloader[cacheId][metric]) {
          this.$set(this.preloader[cacheId], metric, true)
        }

        try {
          let query = {
            project_id: this.activeProjectId,
            catalog_id: '0',
            skip_regionality: 1,
            v2: 1,
            summary: summary ? 1 : undefined,
            limit: summary ? undefined : 1000,
            period_one_date_from: this.monthCurrent.from,
            period_one_date_to: this.monthCurrent.to,
            period_two_date_from: this.monthPrev.from,
            period_two_date_to: this.monthPrev.to,
            traffic_source: 'ga4',
            need_geo: 1,
            from_cache: 1
          }

          let requests = this.getTopGeoIds(metric).map(id => {
            return async () => {
              await this.getDataTopGeo({
                name: cacheId,
                query: {
                  ...query,
                  google_region_id: id,
                  widget_request_id: cacheId + id +  metric
                },
                metric
              })
            }
          })
          await parallelLimit(requests, 2)
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          this.$set(this.preloader[cacheId], metric, false)
        }
      }
    },

    async requestDataChannels({summary, name}) {
      if (this.needsData[name]) {
        this.$set(this.needsData, name, false)

        if (!this.preloader[name]) {
          this.$set(this.preloader, name, true)
        }

        try {
          let query = {
            project_id: this.activeProjectId,
            catalog_id: '0',
            skip_regionality: 1,
            v2: 1,
            summary: summary ? 1 : undefined,
            limit: summary ? undefined : 1000,
            period_one_date_from: this.monthCurrent.from,
            period_one_date_to: this.monthCurrent.to,
            period_two_date_from: this.monthPrev.from,
            period_two_date_to: this.monthPrev.to,
            'traffic_conditions[medium]': 'contains',
            traffic_source: 'ga4',
            from_cache: 1
          }

          let requests = this.filtersTrafficMedium.items.map(medium => {
            return async () => {
              await this.getDataChannels({
                name,
                query: {
                  ...query,
                  medium,
                  widget_request_id: name + medium
                }
              })
            }
          })

          await parallelLimit(requests, 2)
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          this.$set(this.preloader, name, false)
        }
      }
    },

    async requestDataChannelsPeriodDynamic({name, periodSelected, summary, type}) {
      const needsDataFlag = summary
        ? this.needsData[name][type][periodSelected]
        : this.needsData[name][periodSelected]
      const preloaderFlag = summary
        ? this.preloader[name][type][periodSelected]
        : this.preloader[name][periodSelected]

      if (needsDataFlag) {
        if (summary) {
          this.$set(this.needsData[name][type], periodSelected, false)
        } else {
          this.$set(this.needsData[name], periodSelected, false)
        }

        if (!preloaderFlag) {
          if (summary) {
            this.$set(this.preloader[name][type], periodSelected, true)
          } else {
            this.$set(this.preloader[name], periodSelected, true)
          }
        }

        const includesYesterday = this.isDayPeriod(periodSelected)

        try {
          let query = {
            project_id: this.activeProjectId,
            catalog_id: '0',
            skip_regionality: 1,
            v2: 1,
            summary: summary ? 1 : undefined,
            limit: summary ? undefined : 1000,
            ...this.getDates(periodSelected, true),
            'traffic_conditions[medium]': 'contains',
            traffic_source: 'ga4',
            from_cache: includesYesterday ? undefined : 1,
            traffic_all: type === 'ga' ? 1 : 0
          }

          let requests = this.filtersTrafficMedium.items.map(medium => {
            let widget_request_id = name + medium

            if (summary) {
              widget_request_id += `_${type}`
            }

            return async () => {
              await this.getDataChannelsPeriodDynamic({
                name,
                query: {
                  ...query,
                  medium,
                  widget_request_id: includesYesterday ? undefined : widget_request_id
                },
                periodSelected,
                type
              })
            }
          })

          await parallelLimit(requests, 2)
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          if (summary) {
            this.$set(this.preloader[name][type], periodSelected, false)
          } else {
            this.$set(this.preloader[name], periodSelected, false)
          }
        }
      }
    },

    async requestDataEntity({periodSelected, name}) {
      if (this.needsData[name]) {
        this.$set(this.needsData, name, false)

        if (!this.preloader[name]) {
          this.$set(this.preloader, name, true)
        }

        const includesYesterday = this.isDayPeriod(periodSelected)

        try {
          let query = {
            project_id: this.activeProjectId,
            catalog_id: '0',
            skip_regionality: 1,
            v2: 1,
            summary: 1,
            ...this.getDates(periodSelected, true),
            traffic_source: 'ga4',
            from_cache: includesYesterday ? undefined : 1
          }

          const device = name === 'devicesSummaryPeriod'

          const entities = device
            ? this.filtersDevices
            : this.catalogEntities

          let requests = entities.map(({id}) => {
            return async () => {
              await this.getDataEntity({
                query: {
                  ...query,
                  widget_request_id: includesYesterday ? undefined : name + '_' + id + '_' + periodSelected,
                  is_mobile: device ? String(id) : undefined,
                  types: !device ? [id] : undefined
                },
                periodSelected,
                name
              })
            }
          })

          await parallelLimit(requests, 2)
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          this.$set(this.preloader, name, false)
        }
      }
    },

    async requestDataEntityChannelsPeriodDynamic({periodSelected, name}) {
      if (this.needsData[name][periodSelected]) {
        this.$set(this.needsData[name], periodSelected, false)

        if (!this.preloader[name][periodSelected]) {
          this.$set(this.preloader[name], periodSelected, true)
        }

        const includesYesterday = this.isDayPeriod(periodSelected)

        try {
          let query = {
            project_id: this.activeProjectId,
            skip_regionality: 1,
            v2: 1,
            summary: 1,
            catalog_id: '0',
            ...this.getDates(periodSelected, true),
            'traffic_conditions[medium]': 'contains',
            traffic_source: 'ga4',
            from_cache: includesYesterday ? undefined : 1
          }

          let requests = []

          const device = name === 'devicesChannelsPeriod'

          const entities = device
            ? this.filtersDevices
            : this.catalogEntities

          entities.forEach(({id}) => {
            this.filtersTrafficMedium.items.forEach(medium => {
              requests.push(async () => {
                await this.getDataEntityChannelsPeriodDynamic({
                  query: {
                    ...query,
                    medium,
                    widget_request_id: includesYesterday ? undefined : `${name}_` + id + '_' + medium + '_' + periodSelected,
                    is_mobile: device ? String(id) : undefined,
                    types: !device ? [id] : undefined
                  },
                  periodSelected,
                  name
                })
              })
            })
          })

          await parallelLimit(requests, 2)
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          this.$set(this.preloader[name], periodSelected, false)
        }
      }
    },

    async requestDataDevices({summary, name}) {
      if (this.needsData[name]) {
        this.$set(this.needsData, name, false)

        if (!this.preloader[name]) {
          this.$set(this.preloader, name, true)
        }

        try {
          let query = {
            project_id: this.activeProjectId,
            catalog_id: '0',
            skip_regionality: 1,
            v2: 1,
            summary: summary ? 1 : undefined,
            limit: summary ? undefined : 1000,
            period_one_date_from: this.monthCurrent.from,
            period_one_date_to: this.monthCurrent.to,
            period_two_date_from: this.monthPrev.from,
            period_two_date_to: this.monthPrev.to,
            traffic_source: 'ga4',
            from_cache: 1
          }

          let requests = this.filtersDevices.map(({id}) => {
            return async () => {
              await this.getDataDevices({
                name,
                query: {
                  ...query,
                  is_mobile: String(id),
                  widget_request_id: name + id
                }
              })
            }
          })

          await parallelLimit(requests, 2)
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        } finally {
          this.$set(this.preloader, name, false)
        }
      }
    },

    getTopGeoIds(metric) {
      return this.top5RegionsByMetric(metric).map((geo) => geo.id)
    },

    isDayPeriod(period) {
      return period === PERIOD_OPTIONS.DAY_TO_DAY || period === PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK
    },

    getClassByMetric(metric) {
      return this.top5RegionsByMetric(metric).length > 2 ? '_w-100' : ''
    }
  },

  mounted() {
    this.$nextTick(() => {
      const header = document.querySelector('.dashboard-header')
      const headerHeight = header && header.offsetHeight
      this.headerHeight = headerHeight
    })
  }
}
