export default {
  DAYS: {
    WEEK_TO_WEEK: 'за последние 7 дней (к предыдущей неделе)',
    WEEK_TO_WEEK_PAST_YEAR: 'за последние 7 дней (к аналогичному периоду прошлого года)',
    MONTH_TO_MONTH: 'за последние 30 дней (к предыдущему месяцу)',
    MONTH_TO_MONTH_PAST_YEAR: 'за последние 30 дней (к аналогичному периоду прошлого года)'
  },
  PERIOD: {
    WEEK_TO_WEEK: 'неделя к неделе',
    WEEK_TO_WEEK_PAST_YEAR: 'неделя к аналогичному периоду прошлого года',
    MONTH_TO_MONTH: 'месяц к месяцу',
    MONTH_TO_MONTH_PAST_YEAR: 'месяц к аналогичному периоду прошлого года'
  }
}
