<template>
  <v-card
    v-bind="{
        class: '_w-100',
        title,
        description: 'Динамика ключевых показателей ' + label,
        hasData: dynamics.has_data,
        showTags: true,
        load,
        link,
        tags,
        periodSelected,
        id,
        minHeight: '319px'
      }"
    @select-period="$emit('select-period', $event)"
    @hide-card="$emit('hide-card', $event)"
  >
    <div class="metric-list">
      <v-stats-item
        v-for="(metric, i) of metrics"
        :key="i"
        :title="metric.name"
        :value-one="dynamics[`${metric.id}_one`]"
        :value-two="dynamics[`${metric.id}_two`]"
        :diff="dynamics[`${metric.id}_diff`]"
      />
    </div>
  </v-card>
</template>

<script>
import card from '../card'
import statsItem from '../statsItem'

export default {
  name: 'cardDynamics',

  components: {
    'v-card': card,
    'v-stats-item': statsItem
  },

  props: {
    id: {
      type: String,
      default: ''
    },

    load: {
      type: Boolean,
      default: false
    },

    link: {
      type: Object,
      default: () => ({})
    },

    tags: {
      type: Array,
      default: () => ([])
    },

    periodSelected: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      metrics: [
        {id: 'ws2', name: 'Спрос Яндекс («WS»)'},
        {id: 'count_all', name: 'Трафик, Общий (сессии)'},
        {id: 'conversions', name: '%CR'},
        {id: 'transactions', name: 'Продажи (транзакции)'},
        {id: 'transaction_revenue', name: 'Выручка'}
      ]
    }
  },

  computed: {
    dynamics() {
      return this.$store.getters['dashboard/quickStart/summaryPeriod'](this.periodSelected, 'df')
    }
  }
}
</script>

<style scoped lang="scss">
.metric-list {
  display: flex;
  align-items: center;
  column-gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
  width: 100%;
}
</style>
