var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-page-title',{staticClass:"quick-start__title _border-bottom",style:({top: this.headerHeight + 'px'}),attrs:{"primary":_vm.title},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-button',{staticClass:"settings__button",attrs:{"type":"text-base","name":"Настройка стикеров","icon":"settings","offset-icon":"4px","padding-left":"6px","padding-right":"10px","size":"small"},on:{"click":_vm.openSettings}})]},proxy:true}])}),(_vm.preloader.page)?_c('v-preloader'):_c('div',{staticClass:"dashboard-wrapper _border-none"},[_c('v-draggable',_vm._b({staticClass:"cards",attrs:{"handle":".card__drag-handler"},on:{"start":function($event){_vm.draggingActive = true},"end":function($event){_vm.draggingActive = false}},model:{value:(_vm.cardList),callback:function ($$v) {_vm.cardList=$$v},expression:"cardList"}},'v-draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.draggingActive ? 'flip-list' : 'no-move'}},[_vm._l((_vm.cardList),function(card){return [(_vm.cardsConfig[card.id] && _vm.cardsConfig[card.id].component)?_c('component',_vm._b({on:{"select-period":_vm.selectPeriod,"hide-card":_vm.hideCard}},'component',{
                is: `v-${_vm.cardsConfig[card.id].component}`,
                key: card.id,
                ..._vm.cardsConfig[card.id]
              },false)):(_vm.cardsConfig[card.id])?_c('v-card',_vm._b({key:card.id,on:{"hide-card":_vm.hideCard,"move-card":_vm.moveCard},scopedSlots:_vm._u([(_vm.cardsConfig[card.id].buttons)?{key:"buttons",fn:function(){return _vm._l((_vm.cardsConfig[card.id].buttons),function(button,i){return _c('v-button',_vm._b({key:i + button.name},'v-button',button,false))})},proxy:true}:null],null,true)},'v-card',{
              ..._vm.cardsConfig[card.id],
              draggingActive: _vm.draggingActive
            },false),[(_vm.cardsConfig[card.id].type)?_c(`v-category-${_vm.cardsConfig[card.id].type}`,_vm._b({tag:"component"},'component',{
                items: _vm.cardsConfig[card.id].items,
                categoryColors: _vm.cardsConfig[card.id].type !== 'table' ? _vm.entityColors : undefined,
                metric: _vm.cardsConfig[card.id].metric,
                date: _vm.cardsConfig[card.id].type !== 'table' ? _vm.dateFormatted : undefined,
                datePrev: _vm.cardsConfig[card.id].type !== 'table' ? _vm.datePrevFormatted : undefined,
                columns: _vm.cardsConfig[card.id].columns
              },false)):_vm._e()],1):_vm._e()]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }