var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._b({on:{"select-period":function($event){return _vm.$emit('select-period', $event)},"hide-card":function($event){return _vm.$emit('hide-card', $event)}}},'v-card',{
      class: '_w-100',
      title: _vm.title,
      description: 'Динамика ключевых показателей ' + _vm.label,
      hasData: _vm.dynamics.has_data,
      showTags: true,
      load: _vm.load,
      link: _vm.link,
      tags: _vm.tags,
      periodSelected: _vm.periodSelected,
      id: _vm.id,
      minHeight: '319px'
    },false),[_c('div',{staticClass:"metric-list"},_vm._l((_vm.metrics),function(metric,i){return _c('v-stats-item',{key:i,attrs:{"title":metric.name,"value-one":_vm.dynamics[`${metric.id}_one`],"value-two":_vm.dynamics[`${metric.id}_two`],"diff":_vm.dynamics[`${metric.id}_diff`]}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }