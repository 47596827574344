<template>
  <div
      :class="['card', {_hovered: hovered && draggingActive}]"
      :style="styles"
  >
    <div class="card-controls">
      <div
          :class="['card__visibility', {_disabled: disableVisibilityToggle}]"
          @click="hideCard"
      >
        <icon name="visibility_off" />
      </div>
      <icon
          :class="[
            'card__drag-handler',
            {
              _hovered: hovered && draggingActive,
               _grabbed: draggingActive
            }
        ]"
          name="drag_indicator"
          @mousedown.native="hovered = true"
          @mouseup.native="hovered = false"
      />
    </div>
    <div
        v-if="imageName"
        class="card-image"
    >
      <img
          :src="`${getImgUrl()}`"
          :alt="title"
      >
    </div>
    <div class="card-head">
        <span v-if="title" class="card__title">{{ title }}</span>
      <div
          v-if="description"
          class="card__description"
      >
        {{ description }}
      </div>
      <v-page-tags
            v-if="showTags && title"
            class="_p-0 card__tags"
            :tags="tagList"
            :clearable="false"
        />
    </div>

    <v-no-data v-if="!hasData && !load" />

    <template v-else>
      <div :class="['card-content', {'_load': load}]">
        <v-preloader
            v-if="load"
            class="_relative"
        />
        <slot v-else />
      </div>
      <div class="card-buttons">
        <slot name="buttons">
          <v-button
              v-if="link.to"
              type="base"
              name="Подробнее"
              :to="link.to"
          />
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import noData from '@/services/errors/noData'

export default {
  name: 'card',

  components: {
    'v-no-data': noData
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    link: {
      type: Object,
      default: () => ({})
    },

    load: {
      type: Boolean,
      default: false
    },

    imageName: {
      type: String,
      default: ''
    },

    draggingActive: {
      type: Boolean,
      default: false
    },

    hasData: {
      type: Boolean,
      default: true
    },

    tags: {
      type: Array,
      default: () => ([])
    },

    showTags: {
      type: Boolean,
      default: false
    },

    periodSelected: {
      type: String,
      default: ''
    },

    id: {
      type: String,
      default: ''
    },

    minHeight: {
      type: [Number, String],
      default: ''
    }
  },

  data () {
    return {
      hovered: false
    }
  },

  computed: {
    tagList () {
      const checkActive = type => {
        return type === this.periodSelected
      }

      return this.tags.map(tag => {
        return {
          ...tag,
          isActive: checkActive(tag.id),
          callback: () => this.$emit('select-period', {name: this.id, value: tag.id})
        }
      })
    },

    styles () {
      if (this.minHeight) {
        return {minHeight: this.minHeight}
      }
      return {}
    },

    disableVisibilityToggle() {
      return this.$store.state.dashboard.quickStart.widgetConfig.data.filter(card => card.visible).length === 1
    }
  },

  watch: {
    draggingActive(to) {
      if (!to && this.hovered) {
        this.$emit('move-card')
      }
    }
  },

  methods: {
    getImgUrl () {
      return this.imageName ? require('@/assets/svg/others/' + this.imageName + '.svg') : ''
    },

    hideCard() {
      if (!this.disableVisibilityToggle) {
        this.$emit('hide-card', this.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  width: calc(50% - 16px);
  flex-grow: 1;
  height: auto;
  min-height: 454px;
  padding: 24px;
  border: 1px solid #E0E6F0;
  border-radius: 4px;
  background: #fff;

  &._hovered {
    box-shadow: 0 6px 24px rgba(146, 157, 176, 0.45);
    transition: box-shadow .3s ease-out;
  }

  &._w-100 {
    width: 100%;
  }

  &._h-304 {
    min-height: 304px;
  }

  &._h-354 {
    min-height: 354px;
  }

  &._h-505 {
    min-height: 505px;
  }

  &._img {
    padding-top: 242px;
  }

  &-controls {
    display: flex;
    align-items: center;
    column-gap: 15px;
    position: absolute;
    top: 22px;
    right: 22px;
  }

  &__drag-handler,
  &__visibility {
    fill: #9D99AA;

    &:hover {
      cursor: pointer;
    }
  }

  &__drag-handler {
    fill: #9D99AA;

    &:hover {
      cursor: pointer;
    }

    &._hovered {
      fill: #0E32F5;
      transition: all .3s ease-out;
    }

    &._grabbed {
      cursor: grab;
    }
  }

  &__visibility {
    display: flex;
    align-items: center;

    &:hover {
      fill: #0E32F5;
      transition: all .3s ease-out;
    }

    &._disabled {
      fill: #9D99AA;
      cursor: not-allowed;
    }
  }

  &-head {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 24px;
  }

  &-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 218px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-weight: 500;
    max-width: 700px;
    font-size: 20px;
    line-height: 28px;
    color: #455165
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #455165;
  }

  &__tags {
    font-size: 12px;
    line-height: 16px;
    color: #455165;
  }

  &-content {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    &._load {
      flex-grow: 1;
      min-height: 128px;
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    column-gap: 16px;
    width: fit-content;
    margin-top: auto;
  }
}
</style>
